import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { ipcRenderer } from '../../api/api'
import { Button, Form as SemanticForm } from 'semantic-ui-react'
import { Field, Form as FinalForm } from 'react-final-form'
import DatePicker, { registerLocale } from 'react-datepicker'
import moment from 'moment/moment'
import { useForm } from 'react-final-form'
import { Channels } from '../../api/channels'

class BonDeLivraisonSearchForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contacts: [],
    }
  }

  componentDidMount() {
    this.getContactsInState()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      this.getContactsInState()
    }
  }

  getContactsInState = () => {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_ALL, {
        params: {
          isClient: this.props.type === 'VENTE',
          isFournisseur: this.props.type === 'ACHAT',
          isGerant: false,
        },
      })
      .then(({ data }) => {
        this.setState({
          contacts: data,
        })
      })
  }

  handleSubmit = (formValues) => {
    if (formValues.fromDate) {
      formValues.fromDate = moment(formValues.fromDate, 'DD/MM/YYYY')
        .hour(0)
        .minutes(0)
        .second(0)
        .toDate()
    }
    if (formValues.toDate) {
      formValues.toDate = moment(formValues.toDate, 'DD/MM/YYYY')
        .hour(23)
        .minutes(59)
        .second(59)
        .toDate()
    }
    this.props.onSubmit(formValues)
  }

  render() {
    return (
      <FinalForm
        onSubmit={this.handleSubmit}
        initialValues={this.props.initialValues}
        render={({ handleSubmit, form }) => {
          return (
            <SemanticForm onSubmit={handleSubmit}>
              <div className="ui content">
                <SemanticForm.Group>
                  <Field name="numero">
                    {({ input, meta }) => (
                      <SemanticForm.Field>
                        <label>Numéro</label>
                        <SemanticForm.Input
                          {...input}
                          type="text"
                          placeholder="ex: Ac12"
                          autoComplete="off" 
                        />
                      </SemanticForm.Field>
                    )}
                  </Field>
                  <Field name="numeroFacture">
                    {({ input, meta }) => (
                      <SemanticForm.Field>
                        <label>Numéro facture</label>
                        <SemanticForm.Input {...input} type="text" 
                        placeholder="ex: FF-122021-1"
                        autoComplete="off"
                        />
                      </SemanticForm.Field>
                    )}
                  </Field>
                  <Field name="fromDate">
                    {({ input, meta }) => (
                      <SemanticForm.Field>
                        <label>Date début</label>
                        <DatePicker
                          {...input.value}
                          locale="fr"
                          showYearDropdown
                          selected={input.value ? input.value : null}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            if (!date) return ''
                            const dateFmt = new Date(
                              date.setHours(
                                (-1 * date.getTimezoneOffset()) / 60
                              )
                            )

                            // Adapte l'année de l'autre date pour rester dans l'année courante
                            const { toDate } = form.getState().values
                            let toDateUpdated = new Date(toDate)
                            toDateUpdated.setFullYear(dateFmt.getFullYear())
                            form.change(`toDate`, toDateUpdated)

                            input.onChange(dateFmt)
                          }}
                          onSelect={(date) => {
                            if (!date) return ''
                            const dateFmt = new Date(
                              date.setHours(
                                (-1 * date.getTimezoneOffset()) / 60
                              )
                            )

                            // Adapte l'année de l'autre date pour rester dans l'année courante
                            const { toDate } = form.getState().values
                            let toDateUpdated = new Date(toDate)
                            toDateUpdated.setFullYear(dateFmt.getFullYear())
                            form.change(`toDate`, toDateUpdated)

                            input.onChange(dateFmt)
                          }}
                        />
                      </SemanticForm.Field>
                    )}
                  </Field>
                  <Field name="toDate">
                    {({ input, meta }) => (
                      <SemanticForm.Field>
                        <label>Date de fin</label>
                        <DatePicker
                          {...input.value}
                          locale="fr"
                          showYearDropdown
                          selected={input.value ? input.value : null}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            if (!date) return ''
                            const dateFmt = new Date(
                              date.setHours(
                                (-1 * date.getTimezoneOffset()) / 60
                              )
                            )

                            // Adapte l'année de l'autre date pour rester dans l'année courante
                            const { fromDate } = form.getState().values
                            let fromDateUpdated = new Date(fromDate)
                            fromDateUpdated.setFullYear(dateFmt.getFullYear())
                            form.change(`fromDate`, fromDateUpdated)

                            input.onChange(dateFmt)
                          }}
                          onSelect={(date) => {
                            if (!date) return ''
                            const dateFmt = new Date(
                              date.setHours(
                                (-1 * date.getTimezoneOffset()) / 60
                              )
                            )

                            // Adapte l'année de l'autre date pour rester dans l'année courante
                            const { fromDate } = form.getState().values
                            let fromDateUpdated = new Date(fromDate)
                            fromDateUpdated.setFullYear(dateFmt.getFullYear())
                            form.change(`fromDate`, fromDateUpdated)

                            input.onChange(dateFmt)
                          }}
                        />
                      </SemanticForm.Field>
                    )}
                  </Field>

                  <Field name="recepteurId">
                    {({ input, meta }) => {
                      const form = useForm()
                      return (
                        <SemanticForm.Field className="min-width-field">
                          <label>
                            {this.props.type === 'ACHAT'
                              ? 'Fournisseur'
                              : 'Client'}
                          </label>
                          <SemanticForm.Dropdown
                            placeholder="Tous les clients"
                            {...input}
                            search
                            fluid
                            selection
                            className="min-width-field"
                            onBlur={(e) => e.preventDefault()}
                            options={this.state.contacts?.map((contact) => {
                              return {
                                key: contact.id,
                                value: contact.id,
                                text: contact.code + ' - ' + contact.nom,
                              }
                            })}
                            onChange={(event, data) => {
                              const contactOption = this.state.contacts.find(
                                (item) => {
                                  return item.id === data.value
                                }
                              )
                              if (contactOption) {
                                form.change(`recepteurId`, contactOption.id)
                              }
                              input.onChange(data.value)
                            }}
                            onSearchChange={this.handleSearchChange}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                      )
                    }}
                  </Field>
                  </SemanticForm.Group>
                  <SemanticForm.Group>
                  <div className="inline fields">
                    <div className="field multiple-chk">
                      <Field name="isFactureDone" type="checkbox">
                        {({ input, meta }) => (
                          <SemanticForm.Checkbox
                            {...input}
                            toggle
                            label="Facturé"
                            className="child-chk"
                            onChange={(event, { checked }) => {
                              return input.onChange(checked)
                            }}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )}
                      </Field>

                      {/* <Field name="priceless" type="checkbox">
                        {({ input, meta }) => (
                          <SemanticForm.Checkbox
                            {...input}
                            toggle
                            label="Sans prix"
                            className="child-chk"
                            onChange={(event, { checked }) => {
                              return input.onChange(checked)
                            }}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )}
                      </Field> */}
                    </div>

                    <Button type="submit">
                      <i className="icon search"></i>Rechercher
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        // Reset the form
                        form.reset()
                        this.props.onResetSearch()
                      }}
                    >
                      <i className="icon eraser"></i>Effacer
                    </Button>
                  </div>
                </SemanticForm.Group>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default connect(null, null)(BonDeLivraisonSearchForm)
