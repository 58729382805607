import React, { Component, Fragment } from 'react'
import { Field, useForm } from 'react-final-form'
import { Form as SemanticForm } from 'semantic-ui-react'
import { Table, Label, Button } from 'semantic-ui-react'
import { FieldArray } from 'react-final-form-arrays'

import {
  composeValidators,
  minLength,
  isRequired,
  maxLength,
  length,
  mustBeNumber,
} from '../../utils/validators'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'

class ContactFormAdministratif extends Component {
  constructor(props) {
    super(props)
    this.state = this.props?.isTVA ?? false
  }

  componentDidMount() {
    this.setState({ ...this.state, isGerant: this.props?.isGerant }) 
    this.setState({ ...this.state, isProf: this.props?.isTVA })
    this.getMentionsInState()
  }
  
  getMentionsInState = () => {
    if (this.props?.contact?.mentions) {
      const mentions = this.props.contact.mentions
      ipcRenderer.invoke(Channels.MENTION_FIND_ALL, {}).then(({ data }) => {
        this.setState({    
          mentionsSelected: mentions?.map((c) => c.id),
          mentions: data,
        })
      })
    }
    else{
      ipcRenderer.invoke(Channels.MENTION_FIND_ALL, {}).then(({ data }) => {
        this.setState({    
          mentionsSelected:[],
          mentions: data,
        })
      })
    }
  }

  render() {
    const { contact } = this.props
    return contact?(
      <Fragment>
        <SemanticForm.Group className="inline">
          <Field name="isTVA" type="checkbox">
            {({ input, meta }) => (
              <SemanticForm.Checkbox
                {...input}
                toggle
                label="Professionnel (soumis à la TVA) ?"
                onChange={(event, { checked }) => {
                  this.setState({ ...this.state, isProf: checked })
                  return input.onChange(checked)
                }}
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
          {/* <Field name="isCEE" type="checkbox">
            {({ input, meta }) => (
              <SemanticForm.Checkbox
                {...input}
                className={this.state.isProf? '' : 'hidden'}
                toggle
                label="Communauté Européenne"
                onChange={(event, { checked }) => {
                  return input.onChange(checked)
                }}
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field> */}
        </SemanticForm.Group>
        <SemanticForm.Group widths="equal">
          <Field name="numeroTVA">
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                className={this.state.isProf? '' : 'hidden'}
                label="Numéro de TVA"
                type="text"
                placeholder="12312312312"
                autoComplete="off"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
          <Field name="numeroSIRET" validate={composeValidators(length(14))}>
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                className={this.state.isProf? '' : 'hidden'}
                label="Numéro SIRET"
                type="text"
                placeholder="12312312312312"
                autoComplete="off"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
          <Field name="remiseParDefaut">
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                className={this.props.isGerant? 'hidden' : ''}
                label="Remise par défaut (%)"
                type="number"
                placeholder="1"
                autoComplete="off"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
          <Field name="emailComptable">
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                className={this.props.isGerant ? '' : 'hidden'}
                label="Email du comptable"
                type="text"
                placeholder="adresse@email.fr"
                autoComplete="off"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
        </SemanticForm.Group>
    {/*     <SemanticForm.Group  widths="equal">
          <Field name="mentionId">
            {({ input, meta }) => {
              const form = useForm()
              return (
                <SemanticForm.Dropdown
                  placeholder="Tous"
                  {...input}
                  className={this.props.isGerant? 'hidden' : ''}
                  clearable={true}
                  label={'Mentions'}
                  fluid
                  selection
                  onBlur={(e) => e.preventDefault()}
                  options={this.state.mentions?.map((m) => {
                    return {
                      key: m.id,
                      value: m.id,
                      text: m.descriptionCourte,
                    }
                  })}
                  onChange={(event, data) => {
                    const mentionOption = this.state.mentions.find((item) => {
                      return item.id === data.value
                    })
                    if (mentionOption) {
                      form.change(`mentionId`, mentionOption.id)
                    }
                    input.onChange(data.value)
                  }}
                  //onSearchChange={this.handleSearchChange}
                  error={meta.error && meta.touched ? meta.error : null}
                />
              )
            }}
          </Field>
          </SemanticForm.Group> 
          <Field name="input">
              {({ input, meta }) => {
                const form = useForm()
                return (!this.props.isGerant?
                  <Button
                    {...input}
                    icon="add"
                    type="button"
                    size="mini"
                    content="Ajouter"
                    color="teal"
                    onClick={() => {
                      const { mentionId, mentions } =
                        form.getState().values
                        console.log("mentionId", mentionId)
                        const mentionOption = this.state.mentions.find((item) => {
                          return item.id === mentionId
                        })
                        console.log(mentionOption)
                         if (mentionOption) {
                          mentions.push(mentionOption)
                          form.change(`mentions`, mentions)
                          form.change(`mentionId`, null)
                        } 
                    }}
                  />:''
                )
              }}
            </Field>
          <Table celled className={this.props.isGerant? 'hidden' : ''}>
            <FieldArray name="mentions">
              {({ fields, meta }) => (
                <Fragment>
                  <Table.Body>
                    {fields.map((mention, index) => {
                      const form = useForm()
                      console.log("contact", contact)
                      return (                     
                        <Label size="medium" as="a" color="black">
                          {contact.mentions[index]?.descriptionCourte}
                        </Label>
                      )
                    })}
                  </Table.Body>
                </Fragment>
              )}
            </FieldArray>
          </Table> */}

       <Field
          name="mentions"
        >
          {({ input, meta }) => (
            <SemanticForm.Field>
              <SemanticForm.Dropdown
                {...input}
                placeholder="Sélectionnez des mentions"
                search
                multiple
                fluid
                selection
                value={this.state.mentionsSelected}
                onBlur={(e) => e.preventDefault()}
                options={this.state.mentions?.map((mention) => {
                  return {
                    key: mention.id,
                    value: mention.id,
                    text: mention?.descriptionCourte,
                  }
                })}
                label="Mentions associées à ce contact"
                onChange={(event, data) => {
                  this.setState({
                    mentionsSelected: data.value,
                  })
                  return input.onChange(data.value)
                }}
                //onSearchChange={this.handleSearchChangeMentions}
                error={
                  meta.error && meta.touched ? meta.error : null
                }
              />
            </SemanticForm.Field>
          )}
        </Field>  
      </Fragment>
    ):(<div>Chargement</div>)
  }
}

export default ContactFormAdministratif
