const _ = require('lodash');

const updateRemiseIndicative = (form, remiseParDefaut) => {
  form.change('remiseIndicative', remiseParDefaut ? remiseParDefaut : 0)
}

const updateMontantRemiseIndicative = (form) => {
  const {
    totalPrixHT = 0,
    remiseIndicative = 0,
    devisLignes = [],
  } = form.getState().values

  const listeDevis = form.getState().values.devisLignes
  let totalMontantRemiseIndicative = _.sumBy(listeDevis, ({ montantRemiseIndicative }) => {
    if (!isNaN(montantRemiseIndicative)) {
      return parseFloat(parseFloat(montantRemiseIndicative).toFixed(2))
    } else {
      return 0
    }
  })
  
  form.change(
    `totalMontantRemiseIndicative`,
    totalMontantRemiseIndicative.toFixed(2)
  )

  devisLignes.map((item, index) => {
    const montantRemiseLigne = item.prixUnitaireHT
      ? (item.prixUnitaireHT * (item.poidsBrut ? item.poidsBrut : 1) * remiseIndicative) / 100
      : 0.0
    form.change(
      `devisLignes[${index}].montantRemiseIndicative`,
      montantRemiseLigne.toFixed(2)
    )
  })
}

export { updateRemiseIndicative, updateMontantRemiseIndicative }