import React, { Fragment } from 'react'
import FactureForm from './FactureForm'
import { history } from '../../utils/history'
import { DocType } from '../../utils/enum-divers'
import { Button } from 'semantic-ui-react'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { handleErrorResponse } from '../../utils/handle-error-response'
import { toast } from 'react-toastify'
import moment from 'moment'
import EmailModal from '../shared/modal/EmailModal'

class FactureCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultEmetteur: null,
      btnSubmit: null,
      showModalMail: false,
      factureId: 0,
      numFacture: '',
      defaultEmail: '',
    }
  }
  componentDidMount() {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_ALL, {
        params: { isGerant: true },
        order: { nom: 'ASC' },
        take: 1,
      })
      .then(({ data }) => {
        if (data?.length > 0) {
          const [contact] = data
          const adresseAsString = `${contact.nom} \n${
            contact.complement ? contact.complement : ''
          }\n${contact.adresse}\n${contact.codePostal} ${contact.ville}\n${
            contact.pays.libelleFR
          }`
          this.setState({
            defaultEmetteur: {
              id: contact.id,
              asString: adresseAsString,
            },
          })
        }
      })
  }

  modalActionsMail() {
    return (
      <Fragment>
        <button className="ui button teal left labeled icon" type="submit">
          <i className="mail icon"></i>
          Envoyer
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalMail: false,
            })
            history.push(`/factures/${this.props?.match?.params.type}`)
            toast.success(
              `La facture ${this.state.numFacture} a bien été créée mais pas de email envoyé`
            )
          }}
        >
          Fermer
        </button>
      </Fragment>
    )
  }

  emailSubmit = (email, objet, message) => {
    const { type } = this.props?.match?.params

    ipcRenderer
      .invoke(Channels.EMAIL_SEND, {
        email,
        objet,
        message,
        typeDocument: 'Facture',
        idDocument: this.state.factureId,
        numero: this.state.numFacture,
      })
      .then((response) => {
        if (response.error) {
          handleErrorResponse(response.error)
        } else {
          history.push(`/factures/${type}`)
          toast.success(
            `La facture ${this.state.numFacture} a bien été créée et l'email a bien été envoyé`
          )
        }
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  getRecepteurEmail = (id) => {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_BY_ID, id)
      .then((response) => {
        this.setState({
          defaultEmail: response.email,
        })
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  getHeaderActions = () => {
    const { type } = this.props?.match?.params
    return (
      <Fragment>
        <Button
          icon="save"
          type="submit"
          content="Enregistrer"
          onClick={() => {
            this.setState({
              btnSubmit: 'enregistrer',
            })
          }}
          color="teal"
        />
        <Button
          icon="print"
          type="submit"
          content="Imprimer"
          color="green"
          onClick={() => {
            this.setState({
              btnSubmit: 'imprimer',
            })
          }}
        />
        <Button
          icon="mail"
          type="submit"
          content="Email"
          color="blue"
          onClick={() =>
            this.setState({
              btnSubmit: 'email',
            })
          }
        />
        <Button
          icon="reply"
          type="button"
          content="Retour à la liste"
          color="black"
          onClick={() => history.push(`/factures/${type}`)}
        />
      </Fragment>
    )
  }

  onFormSubmit = (formValues) => {
    const { type } = this.props.match.params
    ipcRenderer
      .invoke(Channels.FACTURE_CREATE, formValues)
      .then((response) => {
        if (response.hasError) {
          handleErrorResponse(response.errors)
        } else {
          if (this.state.btnSubmit == 'imprimer') {
            ipcRenderer
                .invoke(Channels.FACTURE_PRINT, response.id)
                .then((response) => {
                 const pdfPath = response.filePath;
                if (pdfPath) {
                  window.open(pdfPath);
                  history.push(`/factures/${type}`)
                  toast.success(`La facture ${response.numero} a bien été créée`);
                } else {
                  toast.error("Le chemin du PDF est manquant ou invalide.");
                }
              })
                .catch((error) => {
                  //console.error(error)
                  toast.error("Erreur lors de l'impression de la facture.");
                })
          } else if (this.state.btnSubmit == 'email') {
            this.getRecepteurEmail(response.recepteurId)
            this.setState(
              {
                factureId: response.id,
                numFacture: response.numero,
              },
              () =>
                this.setState({
                  showModalMail: true,
                })
            )
          } else {
            history.push(`/factures/${type}`)
            toast.success(`La facture ${response.numero} a bien été créée`)
          }
        }
      })
      .catch((error) => {
        console.log(error)
        handleErrorResponse(error)
      })
  }

  render() {
    const { type } = this.props.match.params
    const { defaultEmetteur, factureId, numFacture, defaultEmail } = this.state

    let actualDate = new Date()

    const initialValue = {
      isComptabilise: false,
      isImprime: false,
      type: type === DocType.ACHAT ? DocType.ACHAT : DocType.VENTE,
      date: actualDate,
      dateEcheance: moment(actualDate, 'DD-MM-YYYY').add(30, 'days'),
    }
    if (defaultEmetteur) {
      initialValue.emetteurId = defaultEmetteur.id
      initialValue.emetteurAsString = defaultEmetteur.asString
    }
    return (
      <>
        <FactureForm
          /* formTitle={`Création d'une facture - ${type === DocType.ACHAT ? 'Achats' : 'Ventes'} `} */
          formTitle={`Création d'une facture`}
          type={type === DocType.ACHAT ? DocType.ACHAT : DocType.VENTE}
          headerActions={this.getHeaderActions()}
          onSubmit={this.onFormSubmit}
          initialValues={initialValue}
          creation={true}
          view={false}
        />
        <EmailModal
          title="Envoi de la facture par mail"
          visible={this.state.showModalMail}
          actions={this.modalActionsMail()}
          id={factureId}
          numero={numFacture}
          email={defaultEmail}
          typeDocument="Facture"
          onSubmit={this.emailSubmit}
        />
      </>
    )
  }
}

export default FactureCreate
