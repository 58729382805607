export const CREATE_ORDER = 'CREATE_ORDER'
export const FETCH_ORDERS = 'FETCH_ORDERS'
export const FETCH_ORDER = 'FETCH_ORDER'
export const EDIT_ORDER = 'EDIT_ORDER'
export const DELETE_ORDER = 'DELETE_ORDER'

export const autre = {
  FETCH_COUNTRIES: 'FETCH_COUNTRIES',
}

export const compteComptable = {
  CREATE_COMPTE_COMPTABLE: 'CREATE_COMPTE_COMPTABLE',
  FETCH_COMPTES_COMPTABLES: 'FETCH_COMPTES_COMPTABLES',
  FETCH_COMPTE_COMPTABLE: 'FETCH_COMPTE_COMPTABLE',
  EDIT_COMPTE_COMPTABLE: 'EDIT_COMPTE_COMPTABLE',
  DELETE_COMPTE_COMPTABLE: 'DELETE_COMPTE_COMPTABLE',
}

export const famille = {
  CREATE_FAMILLE: 'CREATE_FAMILLE',
  FETCH_FAMILLES: 'FETCH_FAMILLES',
  FETCH_FAMILLE: 'FETCH_FAMILLE',
  EDIT_FAMILLE: 'EDIT_FAMILLE',
  DELETE_FAMILLE: 'DELETE_FAMILLE',
}

export const variete = {
  CREATE_VARIETE: 'CREATE_VARIETE',
  FETCH_VARIETES: 'FETCH_VARIETES',
  FETCH_VARIETE: 'FETCH_VARIETE',
  EDIT_VARIETE: 'EDIT_VARIETE',
  DELETE_VARIETE: 'DELETE_VARIETE',
}

export const produit = {
  CREATE_PRODUIT: 'CREATE_PRODUIT',
  FETCH_PRODUITS: 'FETCH_PRODUITS',
  FETCH_PRODUIT: 'FETCH_PRODUIT',
  EDIT_PRODUIT: 'EDIT_PRODUIT',
  DELETE_PRODUIT: 'DELETE_PRODUIT',
}

export const emballage = {
  CREATE_EMBALLAGE: 'CREATE_EMBALLAGE',
  FETCH_EMBALLAGES: 'FETCH_EMBALLAGES',
  FETCH_EMBALLAGE: 'FETCH_EMBALLAGE',
  EDIT_EMBALLAGE: 'EDIT_EMBALLAGE',
  DELETE_EMBALLAGE: 'DELETE_EMBALLAGE',
}

export const mention = {
  CREATE_MENTION: 'CREATE_MENTION',
  FETCH_MENTIONS: 'FETCH_MENTIONS',
  FETCH_MENTION: 'FETCH_MENTION',
  EDIT_MENTION: 'EDIT_MENTION',
  DELETE_MENTION: 'DELETE_MENTION',
}

export const parcelle = {
  CREATE_PARCELLE: 'CREATE_PARCELLE',
  FETCH_PARCELLES: 'FETCH_PARCELLES',
  FETCH_PARCELLE: 'FETCH_PARCELLE',
  EDIT_PARCELLE: 'EDIT_PARCELLE',
  DELETE_PARCELLE: 'DELETE_PARCELLE',
}

export const contact = {
  CREATE_CONTACT: 'CREATE_CONTACT',
  FETCH_CONTACTS: 'FETCH_CONTACTS',
  FETCH_CONTACT: 'FETCH_CONTACT',
  EDIT_CONTACT: 'EDIT_CONTACT',
  DELETE_CONTACT: 'DELETE_CONTACT',
}

export const traite = {
  CREATE_TRAITE: 'CREATE_TRAITE',
  FETCH_TRAITES: 'FETCH_TRAITES',
  FETCH_TRAITE: 'FETCH_TRAITE',
  EDIT_TRAITE: 'EDIT_TRAITE',
  DELETE_TRAITE: 'DELETE_TRAITE',
}

export const factureTransporteur = {
  CREATE_FACTURE_TRANSPORTEUR: 'CREATE_FACTURE_TRANSPORTEUR',
  FETCH_FACTURES_TRANSPORTEUR: 'FETCH_FACTURES_TRANSPORTEUR',
  FETCH_FACTURE_TRANSPORTEUR: 'FETCH_FACTURE_TRANSPORTEUR',
  EDIT_FACTURE_TRANSPORTEUR: 'EDIT_FACTURE_TRANSPORTEUR',
  DELETE_FACTURE_TRANSPORTEUR: 'DELETE_FACTURE_TRANSPORTEUR',
}

export const avoir = {
  CREATE_AVOIR: 'CREATE_AVOIR',
  FETCH_AVOIRS: 'FETCH_AVOIRS',
  FETCH_AVOIR: 'FETCH_AVOIR',
  EDIT_AVOIR: 'EDIT_AVOIR',
  DELETE_AVOIR: 'DELETE_AVOIR',
}

export const bonDeLivraison = {
  CREATE_BON_DE_LIVRAISON: 'CREATE_BON_DE_LIVRAISON',
  FETCH_BONS_DE_LIVRAISON: 'FETCH_BONS_DE_LIVRAISON',
  FETCH_BON_DE_LIVRAISON: 'FETCH_BON_DE_LIVRAISON',
  EDIT_BON_DE_LIVRAISON: 'EDIT_BON_DE_LIVRAISON',
  DELETE_BON_DE_LIVRAISON: 'DELETE_BON_DE_LIVRAISON',
}

export const devis = {
  CREATE_DEVIS: 'CREATE_DEVIS',
  FETCH_DES_DEVIS: 'FETCH_DES_DEVIS',
  FETCH_UN_DEVIS: 'FETCH_UN_DEVIS',
  EDIT_DEVIS: 'EDIT_DEVIS',
  DELETE_DEVIS: 'DELETE_DEVIS',
}

export const facture = {
  CREATE_FACTURE: 'CREATE_FACTURE',
  FETCH_FACTURES: 'FETCH_FACTURES',
  FETCH_FACTURE: 'FETCH_FACTURE',
  EDIT_FACTURE: 'EDIT_FACTURE',
  DELETE_FACTURE: 'DELETE_FACTURE',
}

export const statistiques = {
  ACHAT_VENTE: 'ACHAT_VENTE',
  CLIENT: 'CLIENT',
  PARCELLES: 'PARCELLES',
  FOURNISSEUR: 'FOURNISSEUR',
  VENTE_JOURNALIERE: 'VENTE_JOURNALIERE',
}

export const solde = {
  FETCH_SOLDE: 'FETCH_SOLDE',
}

export const prodouane = {
  FETCH_PRODOUANE: 'FETCH_PRODOUANE',
  FETCH_PRODOUANES: 'FETCH_PRODOUANES',
}

export const societe = {
  CREATE_SOCIETE: 'CREATE_SOCIETE',
  FETCH_SOCIETES: 'FETCH_SOCIETES',
  FETCH_SOCIETE: 'FETCH_SOCIETE',
  EDIT_SOCIETE: 'EDIT_SOCIETE',
  DELETE_SOCIETE: 'DELETE_SOCIETE',
}