import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { Form } from 'semantic-ui-react'
import { VisionMode } from '../../../utils/theme'
import { composeValidators, isRequired } from '../../../utils/validators'
import { isEmail } from 'class-validator'

class EmailModal extends React.Component {
  state = {
    objet: '',
    email: '',
    message: '',
    success: false,
    error: false,
    errorMessage: '',
  }

  componentDidMount() {
    switch (this.props.typeDocument) {
      case 'Facture':
        this.setState({
          ...this.state,
          objet: 'Facture numéro - ' + this.props.numero,
          message:
            'Bonjour, \nvous trouverez en PJ la facture numéro ' +
            this.props.numero +
            '\n\nCordialement,',
          email: this.props.email,
        })
        break
      case 'Avoir':
        this.setState({
          ...this.state,
          objet: 'Avoir numéro - ' + this.props.numero,
          message:
            "Bonjour, \nvous trouverez en PJ l'avoir numéro " +
            this.props.numero +
            '\n\nCordialement,',
          email: this.props.email,
        })
        break
      case 'Bl':
        this.setState({
          ...this.state,
          objet: 'Bon de livraison numéro - ' + this.props.numero,
          message:
            'Bonjour, \nvous trouverez en PJ le bon de livraison numéro ' +
            this.props.numero +
            '\n\nCordialement,',
          email: this.props.email,
        })
        break
      case 'Dv':
        this.setState({
          ...this.state,
          objet: 'Devis numéro - ' + this.props.numero,
          message:
            'Bonjour, \nvous trouverez en PJ le devis numéro ' +
            this.props.numero +
            '\n\nCordialement,',
          email: this.props.email,
        })
        break
        case 'Comptable':
        this.setState({
          ...this.state,
          objet: 'Export comptable',
          message:
            'Bonjour, \nvous trouverez en PJ l\'export comptable'+
            '\n\nCordialement,',
          email: this.props.email,
        })
        break
      default:
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.id !== prevProps.id ||
      this.props.email !== prevProps.email
    ) {
      switch (this.props.typeDocument) {
        case 'Facture':
          this.setState({
            ...this.state,
            objet: 'Facture numéro - ' + this.props.numero,
            message:
              'Bonjour, \nvous trouverez en PJ la facture numéro ' +
              this.props.numero +
              '\n\nCordialement,',
            email: this.props.email,
          })
          break
        case 'Avoir':
          this.setState({
            ...this.state,
            objet: 'Avoir numéro - ' + this.props.numero,
            message:
              "Bonjour, \nvous trouverez en PJ l'avoir numéro " +
              this.props.numero +
              '\n\nCordialement,',
            email: this.props.email,
          })
          break
        case 'Bl':
          this.setState({
            ...this.state,
            objet: 'Bon de livraison numéro - ' + this.props.numero,
            message:
              'Bonjour, \nvous trouverez en PJ le bon de livraison numéro ' +
              this.props.numero +
              '\n\nCordialement,',
            email: this.props.email,
          })
          break
        case 'Dv':
          this.setState({
            ...this.state,
            objet: 'Devis numéro - ' + this.props.numero,
            message:
              'Bonjour, \nvous trouverez en PJ le devis numéro ' +
              this.props.numero +
              '\n\nCordialement,',
            email: this.props.email,
          })
          break
          case 'Comptable':
          this.setState({
            ...this.state,
            objet: 'Export Comptable',
            message:
              'Bonjour, \nvous trouverez en PJ l\'export comptable.' +
              '\n\nCordialement,',
            email: this.props.email,
          })
          break
        default:
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { objet, email, message } = this.state
    this.props.onSubmit(email, objet, message)
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({ ...this.state, [name]: value })
  }

  render() {
    const { objet, email, message } = this.state
    const mode =
      this.props.theme.visionMode === VisionMode.AMELIORE
        ? 'ameliore'
        : 'classique'

    return ReactDOM.createPortal(
      <div
        id={this.props.id}
        className={`ui dimmer pulse modals${
          this.props.visible ? ' active visible' : ''
        }`}
      >
        <div
          className={`ui medium modal center${
            this.props.visible ? ' active visible' : ''
          }`}
          onClick={(event) => event.stopPropagation()}
        >
          <div className="header">
            {this.props.title} - {this.props.numero}
          </div>
          <div className="content">
            <Form onSubmit={this.handleSubmit}>
              <Form.Input
                label="Email"
                placeholder="Entrez votre mail"
                name="email"
                type="email"
                value={email}
                onChange={this.handleChange}
                required
                validate={composeValidators(isRequired, isEmail)}
              />
              <Form.Input
                label="Objet"
                placeholder="Objet du mail"
                name="subject"
                value={objet}
                onChange={this.handleChange}
                required
                validate={composeValidators(isRequired)}
              />
              <Form.TextArea
                label="Message"
                placeholder="Entrez votre message"
                name="message"
                value={message}
                onChange={this.handleChange}
                required
                validate={composeValidators(isRequired)}
              />
              <div className={`actions ${mode}`}>{this.props.actions}</div>
            </Form>
          </div>
        </div>
      </div>,
      document.querySelector('#modal')
    )
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
  }
}

export default connect(mapStateToProps)(EmailModal)
