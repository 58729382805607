import React, { Fragment } from 'react'
import DevisForm from './DevisForm'
import { DocType } from '../../utils/enum-divers'
import { Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { history } from '../../utils/history'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { fetchUnDevis } from '../../redux/actions/devis-action'
import CustomModal from '../shared/modal/CustomModal'
import { toast } from 'react-toastify'
import { handleErrorResponse } from '../../utils/handle-error-response'
import EmailModal from '../shared/modal/EmailModal'
import moment from 'moment'

const _ = require('lodash');

class DevisEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModalCancel: false,
      submitBtn: null,
      showModalPrint: false,
      id: 0,
      showModalMail: false,
      numDv: '',
      defaultEmail: '',
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params
    this.props.fetchUnDevis(id)
  }

  modalActionsMail() {
    const { type } = this.props.match.params

    return (
      <Fragment>
        <button className="ui button teal left labeled icon" type="submit">
          <i className="mail icon"></i>
          Envoyer
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalMail: false,
            })
          }}
        >
          Fermer
        </button>
      </Fragment>
    )
  }

  emailSubmit = (email, objet, message) => {
    const { type } = this.props?.match?.params

    ipcRenderer
      .invoke(Channels.EMAIL_SEND, {
        email,
        objet,
        message,
        typeDocument: 'Dv',
        idDocument: this.state.id,
        numero: this.props.devis.numero,
      })
      .then((response) => {
        if (response.error) {
          handleErrorResponse(response.error)
        } else {
          history.push(`/devis/${type}`)
          toast.success(
            `Le devis ${this.props.devis.numero} a bien été créé et l'email a bien été envoyé`
          )
        }
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  getRecepteurEmail = (id) => {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_BY_ID, id)
      .then((response) => {
        this.setState({
          defaultEmail: response.email,
        })
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button
          icon="save"
          type="submit"
          content="Enregistrer"
          color="teal"
          disabled={
            !this.props.devis ||
            this.props.devis.isFactureDone
          }
          onClick={() => {
            this.setState({ ...this.state, submitBtn: 'enregistrer' })
          }}
        />
        <Button
          icon="print"
          type="submit"
          content="Imprimer"
          color="green"
          onClick={() => {
            this.setState({ ...this.state, submitBtn: 'imprimer' })
          }}
        />
        <Button
          icon="mail"
          type="submit"
          content="Email"
          color="blue"
          onClick={() =>
            this.setState({
              submitBtn: 'email',
            })
          }
        />
        <Button
          icon="file alternate outline"
          type="submit"
          content="Facturer"
          color="red"
          disabled={
            !this.props.devis ||
            this.props.devis.isFactureDone || 
            !this.props.devis.isSigned
          }
          onClick={() => {
            this.setState({ ...this.state, submitBtn: 'facturer' })
          }}
        />
        <Button
          icon="file alternate outline"
          type="submit"
          content="Livrer"
          color="orange"
          onClick={() => {
            this.setState({ ...this.state, submitBtn: 'livrer' })
          }}
        />
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: true,
            })
          }}
        >
          Annuler
        </button>
      </Fragment>
    )
  }

  modalActionsCancel() {
    const { type } = this.props?.match?.params
    return (
      <Fragment>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
            history.push(history.push(`/devis/${type}`))
          }}
        >
          <i className="checkmark icon"></i>
          Oui
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
          }}
        >
          Non
        </button>
      </Fragment>
    )
  }

  modalActionsPrint(id) {
    const { type } = this.props.match.params

    return (
      <Fragment>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalPrint: false,
            })
            ipcRenderer
            .invoke(Channels.DEVIS_PRINT, {
              data: id,
              options: { printParcelles: true },
            })
            .then((response) => {
              //console.log('API Response:', response);
              const pdfPath = response.filePath;
              if (pdfPath) {
                window.open(pdfPath);
                toast.success(`Le devis a bien été créé`);
                history.push(`/devis/view/${id}`);
              } else {
                toast.error("Le chemin du PDF est manquant ou invalide.");
              }
            })
            .catch((error) => {
              console.error(error);
              toast.error('Erreur lors de la création du devis.');
            });
          }}
        >
          <i className="checkmark icon" />
          Impression PDF
        </button>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalPrint: false,
              })
            }
          }  
        >
          Non
        </button>
      </Fragment>
    )
  }

 onFormSubmit = async (formValues) => {
    const { type } = this.props.match.params;

    if (this.state.submitBtn == 'modifier') {
      try { 
        formValues.date = moment(Date.now(), 'DD-MM-YYYY')
        formValues.numero = 'DEV'
        ipcRenderer.invoke(Channels.DEVIS_CREATE, formValues)
          .then((response) => {
            if (response.hasError) {
              handleErrorResponse(response.errors)
            } else {
              
            }
          })
          .catch((error) => {
            console.log(error)
            handleErrorResponse(error)
          })
      } catch (error) {
        console.log(error);
        handleErrorResponse(error);
      }
      return
    }

    try {
        const response = await ipcRenderer.invoke(Channels.DEVIS_UPDATE, {
            id: this.props.match.params.id,
            devis: formValues,
        });

        console.log(`Reponse: ${response}`)
        if (response.hasError) {
            handleErrorResponse(response.errors);
            return;
        }

        this.setState({ id: response.id });
        switch (this.state.submitBtn) {
            case 'imprimer':
                this.setState({ showModalPrint: true });
                break;
            case 'email':
                this.getRecepteurEmail(response.recepteurId);
                this.setState({ numero: response.numero, showModalMail: true });
                break;
            case 'facturer': {
                ipcRenderer
                .invoke(Channels.BON_DE_LIVRAISON_CREATE_FROM_DEVIS, response)
                .then((bon_de_livraison_response) => {
                  if (bon_de_livraison_response.hasError) {
                    handleErrorResponse(bon_de_livraison_response.errors)
                  } else {
                    toast.success(`Le bon de livraison ${bon_de_livraison_response.numero} a bien été créé`)

                    //calcul de la quantité totale
                    const lignes = formValues.devisLignes
                    let quantite = _.sumBy(lignes, ({ poidsNet }) => {
                      if (!isNaN(poidsNet)) {
                        return parseFloat(parseFloat(poidsNet).toFixed(2))
                      } else {
                        return 0
                      }
                    }) 
                    // Construction de la facture à partir du Dv
                    let facture = { isComptabilise:false,
                                    isImprime: false,
                                    date: bon_de_livraison_response.date, // RAF today
                                    dateEcheance : moment(bon_de_livraison_response.date).add(30, 'days'),
                                    emetteurId: bon_de_livraison_response.emetteurId,
                                    emetteurAsString:bon_de_livraison_response.emetteurAsString,
                                    recepteurId: bon_de_livraison_response.recepteurId,
                                    recepteurAsString : bon_de_livraison_response.recepteurAsString,
                                    type: bon_de_livraison_response.type,
                                    mentions: bon_de_livraison_response.mentions,
                                    bonsDeLivraisons: [{
                                      id: bon_de_livraison_response.id,
                                      numero: bon_de_livraison_response.numero,
                                      bonDeLivraisonLignes: bon_de_livraison_response.bonDeLivraisonLignes
                                    }],
                                    listeDevis: [{
                                      id: response.id,
                                      numero: response.numero,
                                      devisLignes: response.devisLignes
                                    }],
                                    totalHTAvantRemise: bon_de_livraison_response.totalPrixHT + bon_de_livraison_response.totalMontantRemiseIndicative,
                                    montantRemise: bon_de_livraison_response.totalMontantRemiseIndicative,
                                    totalHTApresRemise: bon_de_livraison_response.totalPrixHT,
                                    totalTVA_2_1: bon_de_livraison_response.totalTVA_2_1,
                                    totalTVA_5_5: bon_de_livraison_response.totalTVA_5_5,
                                    totalTVA_10: bon_de_livraison_response.totalTVA_10,
                                    totalTVA_20: bon_de_livraison_response.totalTVA_20,
                                    montantTVA: bon_de_livraison_response.totalTVA,
                                    totalTTC: bon_de_livraison_response.totalTTC,
                                    poidsNet: quantite,
                                    dateDeReglement: null,
                                    dateEncaissement: null,
                                    totalCotisation: 0,
                                    tauxDeTVA: 0,
                                    remise: 0,
                                  }
                    ipcRenderer
                    .invoke(Channels.FACTURE_CREATE, facture)
                    .then((response_facture) => {
                      if (response_facture.hasError) {
                        handleErrorResponse(response_facture.errors)
                      } else {
                        history.push(`/factures/${type}/edit/${response_facture.id}`)
                        toast.success(`La facture ${response_facture.numero} a bien été créée`)
                      }
                    })
                  }
                })

                
              }
              break;
            case 'livrer':
              ipcRenderer
              .invoke(Channels.BON_DE_LIVRAISON_CREATE_FROM_DEVIS, response)
              .then((bon_de_livraison_response) => {
                if (bon_de_livraison_response.hasError) {
                  handleErrorResponse(bon_de_livraison_response.errors)
                } else {
                  history.push(`/bons-de-livraison/${type}/edit/${bon_de_livraison_response.id}`)
                  toast.success(`Le bon de livraison ${bon_de_livraison_response.numero} a bien été créé`)
                }
              })
            case 'modifier':
              break;              
            default:
                history.push(`/devis/${type}`);
                toast.success(`Le devis ${response.numero} a bien été mis à jour`);
                break;
        }
    } catch (error) {
        console.log(error);
        handleErrorResponse(error);
    }
  };

  render() {
    const { devis , TVA_2_1_visible, TVA_5_5_visible, TVA_10_visible, TVA_20_visible} = this.props
    //console.log(devis)
    const initialValues = devis
    const { defaultEmail, id, numDv } = this.state

    return initialValues ? (
      <Fragment>
        <DevisForm
          /* formTitle={`Editer un devis - ${initialValues.type === DocType.ACHAT ? 'Achats' : 'Ventes'} `} */
          formTitle={`Editer un devis`}
          type={
            initialValues.type === DocType.ACHAT ? DocType.ACHAT : DocType.VENTE
          }
          headerActions={this.getHeaderActions()}
          initialValues={initialValues}
          onSubmit={this.onFormSubmit}
          view={
            !this.props.devis ||
            this.props.devis.isFactureDone
          }
        />
        <EmailModal
          title="Envoi du devis par mail"
          visible={this.state.showModalMail}
          actions={this.modalActionsMail()}
          id={id}
          numero={this.props.devis.numero}
          email={defaultEmail}
          typeDocument="Dv"
          onSubmit={this.emailSubmit}
        />
        <CustomModal
          title="Annuler"
          content="Quitter et annuler les modifications en cours ?"
          visible={this.state.showModalCancel}
          actions={this.modalActionsCancel()}
        />
        <CustomModal
          title="Imprimer"
          content="Imprimer le devis"
          visible={this.state.showModalPrint}
          actions={this.modalActionsPrint(this.state.id)}
        />
      </Fragment>
    ) : (
      <div>Chargement</div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    devis: state.listeDevis.devis,
    theme: state.theme,
  }
}

export default connect(mapStateToProps, { fetchUnDevis })(
  DevisEdit
)
