import { CustomError, ErrorFormated } from './errors/custom-error'

export class ipcRenderer {
  public static async invoke(channel: any, query: any): Promise<any> {
    try {
      let societeId = JSON.parse(localStorage.getItem('societeId')||'{}')

       // On passe le societeId dans la query
      if(typeof query != 'string' && typeof query != 'number')
      query.societeId = societeId
      

      if (process.env.NODE_ENV != 'prod') {
        console.log("APPEL API")
        console.log(channel)
        //console.log("ID")
        //console.log(societeId)
        console.log(query)
      }
      
    /*    
    const channel = Channels.MENTION_FIND_ALL
      fetch("http://localhost:3001/", {method: 'POST', headers: {'Content-Type': 'application/json', 'Accept':'application/json'}, body: JSON.stringify({channel, query})
    }).then(res => {
        res.json().then(response =>{
      */

      const API  = (process.env.NODE_ENV === 'development') ? (process.env.REACT_APP_API_DEV || 'http://localhost:3001/') : (process.env.REACT_APP_API_PROD || 'http://localhost:3001/')
      let reponse = await fetch( API, {method: 'POST', headers: {'Content-Type': 'application/json', }, body: JSON.stringify({ channel, query}) })
      let result = null
    
      if (reponse.headers.get("content-length")  != "0") {
        result = await reponse.json()
      }

      let data = {}

      if(result?.data != undefined){
        /* data = result.data
        data = {data} */
        data = result
      }
      else {
        data = result
      }
      return await Promise.resolve(data);
    } 
    catch (error: unknown) {
      if(error instanceof Error ) console.log(error)
      return  CustomError.formatError(error)
    }
  }
}