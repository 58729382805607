import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { toast } from 'react-toastify'
import { devis } from './types'

export const fetchUnDevis = (id) => {
  console.log('fetch action')
  return async (dispatch) => {
    try {
      const payload = await ipcRenderer.invoke(
        Channels.DEVIS_FIND_BY_ID,
        id
        )
        console.log(payload)
      if (payload.hasError) {
        throw new Error(payload.error?.toString())
      } else {
        dispatch({
          type: devis.FETCH_UN_DEVIS,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération d'un devis")
    }
  }
}

export const fetchDesDevis = (query) => {
  return async (dispatch) => {
    try {
      const response = await ipcRenderer.invoke(
        Channels.DEVIS_FIND_ALL,
        query
        )
        const payload = { response, query }
      if (response.hasError) {
        throw new Error(response.error?.toString())
      } else {
        dispatch({
          type: devis.FETCH_DES_DEVIS,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération des devis")
    }
  }
}
