import React from 'react'
import { Button, Form as SemanticForm, Table } from 'semantic-ui-react'
import { Field } from 'react-final-form'
import { ipcRenderer } from '../../../../api/api'
import { useForm } from 'react-final-form'
import { Channels } from '../../../../api/channels'
import { composeValidators, isRequired } from '../../../../utils/validators'
import TypeDevis from '../../../../api/enum/type-devis'
import { updateMontantRemiseIndicative } from '../common-form-update'
import './DevisFormLigne.css'
const _ = require('lodash');

class DevisFormLigne extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      produits: [],
      parcelles: [],
      isTTC: false
    }
  }

  componentDidMount() {
    // ipcRenderer.invoke(Channels.EMBALLAGE_FIND_ALL, {}).then(({ data }) => {
    //   this.setState({
    //     emballages: data,
    //   })
    // })
    ipcRenderer.invoke(Channels.PARCELLE_FIND_ALL, {}).then(({ data }) => {
      this.setState({
        parcelles: data,
      })
    })
    ipcRenderer
      .invoke(Channels.PRODUIT_FIND_ALL, {
        order: { libelle: 'ASC' },
        params: {
          isActive: true,
        },
      })
      .then(({ data }) => {
        //console.log(data)
        this.setState({
          produits: data,
        })
      })
  }

  chargerParcelles(formValues, produitId) {
    const produit = this.state.produits.find((p) => p.id === produitId)
    const params = {
      varieteId: produit.varieteId,
    }
    if (formValues.type === TypeDevis.ACHAT) {
      params.contactId = formValues.recepteurId
    }
    ipcRenderer
      .invoke(Channels.PARCELLE_FIND_ALL, { params: params })
      .then(({ data }) => {
        this.setState({
          parcelles: data,
        })
      })
  }

  /**
   *
   * @param form
   */
  updatePoidsNet = (form) => {
    const {
      poidsBrut,
      nbColis,
      tare = 0,
    } = form.getState().values.devisLignes[this.props.index]
    let poidsNet = poidsBrut ? poidsBrut : 0.0
    if (poidsBrut && nbColis && !isNaN(poidsBrut) && !isNaN(nbColis)) {
      poidsNet = poidsBrut - nbColis * tare
    }
    if (typeof poidsNet === 'string') {
      poidsNet = parseFloat(poidsNet)
    }
    form.change(
      `devisLignes[${this.props.index}].poidsNet`,
      poidsNet.toFixed(2)
    )
  }
  /**
   *
   * @param form
   */
  updateTotalLigneHT = (form) => {
    const { poidsNet, prixUnitaireHT, tauxDeTVA, montantRemiseIndicative, prixUnitaireTTC } =
      form.getState().values.devisLignes[this.props.index]
    let totalLigneHT = 0
    let totalMontantTVA = 0 
    let totalLigneTTC = 0
    let remise = 0
    if(montantRemiseIndicative !== 0)
      remise = poidsNet * prixUnitaireHT * montantRemiseIndicative /100

    if (poidsNet && prixUnitaireHT) { 
      totalLigneHT = poidsNet * prixUnitaireHT - remise
      totalMontantTVA = (totalLigneHT * tauxDeTVA/100)

      if (form.getState().values.TTC) {
        totalLigneTTC = prixUnitaireTTC * poidsNet
      } else {
        totalLigneTTC = (totalLigneHT * tauxDeTVA/100) + totalLigneHT
      }
    }
    
    form.change(
      `devisLignes[${this.props.index}].totalLigneHT`,
      Number.isInteger(totalLigneHT) ? totalLigneHT : totalLigneHT.toFixed(2)
    )
    form.change( 
      `devisLignes[${this.props.index}].totalTVA`,
      Number.isInteger(totalMontantTVA) ? totalMontantTVA : totalMontantTVA.toFixed(2)
    )
    form.change(
      `devisLignes[${this.props.index}].totalLigneTTC`,
      Number.isInteger(totalLigneTTC) ? totalLigneTTC : totalLigneTTC.toFixed(2)
    )
  }

  // validateCodeParcelle = (field, allValues, state) => {
  //   if ((state.dirty && field) || state.active) {
  //     return _.memoize(async (field, allValues, state) => {
  //       const params = { code: field }
  //       if (allValues.type === TypeDevis.ACHAT) {
  //         params.contactId = allValues.recepteurId
  //       }
  //       const { data } = await ipcRenderer.invoke(Channels.PARCELLE_FIND_ALL, {
  //         params: params,
  //       })
  //       if (data.length === 0) {
  //         return 'Parcelle non valide'
  //       }
  //       return null
  //     })(field, allValues, state)
  //   } else {
  //     return null
  //   }
  // }

  /**
   *
   * @param form
   */
  updateTotalRemise = (form) => {
    const allDVLines = form.getState().values.devisLignes

    const montantRemise =
      _.sumBy(allDVLines, (o) => (parseFloat(o.poidsNet)*parseFloat(o.prixUnitaireHT)*parseFloat(o.montantRemiseIndicative)/100))
    form.change(
      `totalMontantRemiseIndicative`,
      Number.isInteger(montantRemise) ? montantRemise : montantRemise.toFixed(2)
    )
  }

  updateTotalHT = (form) => {
    const listeDevis = form.getState().values.devisLignes
    let totalPrixHT = _.sumBy(listeDevis, ({ totalLigneHT }) => {
      if (!isNaN(totalLigneHT)) {
        return parseFloat(parseFloat(totalLigneHT).toFixed(2))
      } else {
        return 0
      }
    })
    form.change(`totalPrixHT`, totalPrixHT.toFixed(2))
  }

  /*Cette fonction prend les lignes d'un devis, 
  additionne les montants TVA pour chaque ligne,
  et met à jour le formulaire avec le montant total TVA*/
  updateTotalMontantTVA = (form) => {
    const listeDevis = form.getState().values.devisLignes

    let totalHT = _.sumBy(listeDevis, ({ totalLigneHT }) => {
      if (!isNaN(totalLigneHT)) {
        return parseFloat(parseFloat(totalLigneHT).toFixed(2))
      } else {
        return 0
      }
    })

    
    let totalTVA_2_1 = _.sumBy(listeDevis, ({ totalLigneHT, tauxDeTVA }) => {
      if (!isNaN(totalLigneHT) && tauxDeTVA == 2.1) {
        return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
      } else {
        return 0
      }
    })

    let totalTVA_5_5 = _.sumBy(listeDevis, ({ totalLigneHT, tauxDeTVA }) => {
      if (!isNaN(totalLigneHT) && tauxDeTVA == 5.5) {
        return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
      } else {
        return 0
      }
    })

    let totalTVA_10 = _.sumBy(listeDevis, ({ totalLigneHT, tauxDeTVA }) => {
      if (!isNaN(totalLigneHT) && tauxDeTVA == 10) {
        return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
      } else {
        return 0
      }
    })

    let totalTVA_20 = _.sumBy(listeDevis, ({ totalLigneHT, tauxDeTVA }) => {
      if (!isNaN(totalLigneHT) && tauxDeTVA == 20) {
        return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
      } else {
        return 0
      }
    })
    
    let totalTTC = _.sumBy(listeDevis, ({ totalLigneTTC }) => {
      if (!isNaN(totalLigneTTC)) {
        return parseFloat(parseFloat(totalLigneTTC).toFixed(2))
      } else {
        return 0
      }
    })

    this.props.onUpdateTVA(totalTVA_2_1 != 0, totalTVA_5_5!=0, totalTVA_10!=0, totalTVA_20!=0)
    form.change(`totalTVA`, (totalTTC - totalHT).toFixed(2))
    form.change(`totalTVA_2_1`, (totalTVA_2_1).toFixed(2))
    form.change(`totalTVA_5_5`, (totalTVA_5_5).toFixed(2))
    form.change(`totalTVA_10`, (totalTVA_10).toFixed(2))
    form.change(`totalTVA_20`, (totalTVA_20).toFixed(2))
  }

  updateLigneTTC = (form) => {
    const listeDevis = form.getState().values.devisLignes

    let totalTTC = _.sumBy(listeDevis, ({ totalLigneTTC }) => {
      if (!isNaN(totalLigneTTC)) {
        return parseFloat(parseFloat(totalLigneTTC).toFixed(2))
      } else {
        return 0
      }
    })

    form.change(`totalTTC`, totalTTC.toFixed(2))
  }

  render() {
    const { index, ligne, view, isTTC } = this.props

    return (
      <Table.Row key={index}>
        {/* <Table.Cell>
          <Field
            name={`devisLignes[${index}].nbColis`}
            validate={composeValidators(isRequired)}
          >
            {({ input, meta }) => {
              const form = useForm()
              return (
                <SemanticForm.Input
                  {...input}
                  type="number"
                  className="in-array"
                  readOnly={view}
                  error={meta.error && meta.touched ? meta.error : null}
                  onChange={(event, data) => {
                    input.onChange(data.value)
                    this.updatePoidsNet(form)
                    this.updateTotalLigneHT(form)
                    this.updateTotalHT(form)
                    updateMontantRemiseIndicative(form)
                  }}
                />
              )
            }}
          </Field>
        </Table.Cell> */}
          <Table.Cell rowSpan="1">
          <Field name={`devisLignes[${index}].produitId`}>
            {({ input }) => (
              <input {...input} type="hidden" className="in-array" />
            )}
          </Field>
          <Field
            name={`devisLignes[${index}].codeProduit`}
            validate={composeValidators(isRequired)}
          >
            {({ input, meta }) => {
              const form = useForm()
              if (this.props.view) {
                return (
                  <SemanticForm.Input
                    {...input}
                    type="text"
                    className="in-array"
                    readOnly={view}
                    error={meta.error && meta.touched ? meta.error : null}
                    onChange={(event, data) => {
                      input.onChange(data.value)
                      this.updatePoidsNet(form)
                      this.updateTotalLigneHT(form)
                      //updateMontantRemiseIndicative(form)
                      this.updateTotalRemise(form)
                      this.updateTotalHT(form)
                      this.updateTotalMontantTVA(form)
                      this.updateLigneTTC(form)
                    }}
                  />
                )
              } else {
                return (
                  <SemanticForm.Field>
                    <SemanticForm.Dropdown
                      {...input}
                      search
                      fluid
                      selection
                      disabled={view}
                      onBlur={(e) => e.preventDefault()}
                      options={this.state.produits.map((produit) => {
                        return {
                          key: produit.id,
                          value: produit.code,
                          text: produit.code,
                          libelle: produit.libelle,
                        }
                      })}
                      onChange={(event, data) => {
                        const productOption = this.state.produits.find(
                          (item) => {
                            return item.code === data.value
                          }
                        )
                        //console.log(productOption)
                        this.chargerParcelles(
                          form.getState().values,
                          productOption.id,
                          console.log(form.getState().values)
                        )
                        if (productOption) {
                          //Calcul de prixTTC
                          const prixTTC = productOption.prixHT * (1+productOption.tauxDeTVA/100)
                          
                          form.change(
                            `devisLignes[${index}].produitId`,
                            productOption.id
                          )
                          form.change(
                            `devisLignes[${index}].libelleProduit`,
                            productOption.libelle
                          )
                          form.change(
                            `devisLignes[${index}].descriptionProduit`,
                            productOption.description
                          )
                          form.change(
                            `devisLignes[${index}].prixUnitaireTTC`,
                            prixTTC
                          )
                          form.change(
                            `devisLignes[${index}].prixUnitaireHT`,
                            productOption.prixHT
                          )
                          form.change(
                            `devisLignes[${index}].tauxDeTVA`,
                            productOption.tauxDeTVA
                          )
                        }
                        this.updatePoidsNet(form)
                        this.updateTotalLigneHT(form)
                        this.updateTotalHT(form)
                        //updateMontantRemiseIndicative(form)
                        this.updateTotalRemise(form)
                        this.updateTotalMontantTVA(form)
                        this.updateLigneTTC(form)
                        return input.onChange(data.value)
                      }}
                      onClose={(event, data) => {
                        const productOption = this.state.produits.find(
                          (item) => {
                            return item.code === data.value
                          }
                        )
                        //console.log(productOption)
                        /*if (productOption) {
                          form.change(
                            `devisLignes[${index}].produitId`,
                            productOption.id
                          )
                          form.change(
                            `devisLignes[${index}].libelleProduit`,
                            productOption.libelle
                          )
                        }*/
                      }}
                      error={meta.error && meta.touched ? meta.error : null}
                    />
                  </SemanticForm.Field>
                )
              }
            }}
          </Field>
        </Table.Cell>
        <Table.Cell>
          <Field
            name={`devisLignes[${index}].libelleProduit`}
            validate={composeValidators(isRequired)}
          >
            {({ input, meta }) => {
              return (
                <SemanticForm.Input
                  {...input}
                  type="text"
                  className="in-array"
                  readOnly={view}
                  onWheel={(e) => {
                    e.target.blur(); // Retire le focus lorsque l'utilisateur utilise la molette
                    /* e.preventDefault();  */// Empêche l'événement par défaut
                  }}
                  error={meta.error && meta.touched ? meta.error : null}
                />
              )
            }}
          </Field>
        </Table.Cell>
        <Table.Cell colSpan="5">
          <Field
            name={`devisLignes[${index}].descriptionProduit`}
          >
            {({ input, meta }) => {
              return (
                <SemanticForm.TextArea
                  {...input}
                  type="text"
                  className="in-array"
                  readOnly={view}
                  onWheel={(e) => {
                    e.target.blur();
                  }}
                  error={meta.error && meta.touched ? meta.error : null}
                  style={{ width: "100%"}}
                />
              )
            }}
          </Field>
        </Table.Cell>
        {/*<Table.Cell>*/}
        {/*  <Field*/}
        {/*    name={`devisLignes[${index}].categorie`}*/}
        {/*    validate={composeValidators(isRequired)}*/}
        {/*  >*/}
        {/*    {({ input, meta }) => (*/}
        {/*      <SemanticForm.Input*/}
        {/*        {...input}*/}
        {/*        type="text"*/}
        {/*        className="in-array"*/}
        {/*        readOnly={view}*/}
        {/*        error={meta.error && meta.touched ? meta.error : null}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  </Field>*/}
        {/*</Table.Cell>*/}
        <Table.Cell>
          <Field
            name={`devisLignes[${index}].poidsBrut`}
            validate={composeValidators(isRequired)}
          >
            {({ input, meta }) => {
              const form = useForm()
              return (
                <SemanticForm.Input
                  {...input}
                  type="number"
                  className="in-array"
                  readOnly={view}
                  width={100}
                  // Suppression de l'effet de la roulette de la souris qui modifiait les valeurs dans le champ :
                  onWheel={(e) => {
                    e.target.blur(); // Retire le focus lorsque l'utilisateur utilise la molette
                    /* e.preventDefault();  */// Empêche l'événement par défaut
                  }}
                  error={meta.error && meta.touched ? meta.error : null}
                  onChange={(event, data) => {
                    input.onChange(data.value)
                    this.updatePoidsNet(form)
                    this.updateTotalLigneHT(form)
                    this.updateTotalRemise(form)
                    this.updateTotalHT(form)
                    this.updateTotalMontantTVA(form)
                    this.updateLigneTTC(form)
                    //updateMontantRemiseIndicative(form)
                  }}
                />
              )
            }}
          </Field>
        </Table.Cell>
        {/*<Table.Cell>*/}
        {/*  <Field*/}
        {/*    name={`devisLignes[${index}].tare`}*/}
        {/*    validate={composeValidators(isRequired)}*/}
        {/*  >*/}
        {/*    {({ input, meta }) => {*/}
        {/*      const form = useForm()*/}
        {/*      return (*/}
        {/*        <SemanticForm.Input*/}
        {/*          {...input}*/}
        {/*          type="number"*/}
        {/*          className="in-array"*/}
        {/*          readOnly={view}*/}
        {/*          error={meta.error && meta.touched ? meta.error : null}*/}
        {/*          onChange={(event, data) => {*/}
        {/*            input.onChange(data.value)*/}
        {/*            this.updatePoidsNet(form)*/}
        {/*            this.updateTotalLigneHT(form)*/}
        {/*            this.updateTotalHT(form)*/}
        {/*            updateMontantRemiseIndicative(form)*/}
        {/*          }}*/}
        {/*        />*/}
        {/*      )*/}
        {/*    }}*/}
        {/*  </Field>*/}
        {/*</Table.Cell>*/}
        {/*<Table.Cell>*/}
        {/*  <Field*/}
        {/*    name={`devisLignes[${index}].poidsNet`}*/}
        {/*    validate={composeValidators(isRequired)}*/}
        {/*  >*/}
        {/*    {({ input, meta }) => (*/}
        {/*      <SemanticForm.Input*/}
        {/*        {...input}*/}
        {/*        type="number"*/}
        {/*        className="in-array"*/}
        {/*        readOnly={true}*/}
        {/*        error={meta.error && meta.touched ? meta.error : null}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  </Field>*/}
        {/*</Table.Cell>*/}
        {isTTC ?
        <Table.Cell>
          <Field
            name={`devisLignes[${index}].prixUnitaireTTC`}
            validate={composeValidators(isRequired)}
          >
            {({ input, meta }) => {
              const form = useForm()
              return (
                <SemanticForm.Input
                  {...input}
                  type="number"
                  className="in-array"
                  readOnly={view}
                  width={100}
                  onWheel={(e) => {
                    e.target.blur();
                  }}
                  error={meta.error && meta.touched ? meta.error : null}
                  onChange={(event, data) => {
                    input.onChange(data.value)

                    const prixTTC = parseFloat(data.value) || 0;
                    const formState = form.getState();
                    const bons = formState.values.devisLignes || [];
                    const tauxTVA = bons[0]?.tauxDeTVA ?? 20;
                    console.log("formState  :", formState) 

                    // Calcul du prix HT
                    const prixHT = prixTTC / (1 + tauxTVA / 100);

                    form.change(`devisLignes[${index}].prixUnitaireHT`, prixHT);

                    this.updateTotalLigneHT(form)
                    this.updateTotalRemise(form)
                    this.updateTotalHT(form)
                    this.updateTotalMontantTVA(form)
                    this.updateLigneTTC(form)
                  }}
                />
              )
            }}
          </Field>
        </Table.Cell> : 
        <Table.Cell>
          <Field
            name={`devisLignes[${index}].prixUnitaireHT`}
            validate={composeValidators(isRequired)}
          >
            {({ input, meta }) => {
              const form = useForm()
              return (
                <SemanticForm.Input
                  {...input}
                  type="number"
                  className="in-array"
                  readOnly={view}
                  width={100}
                  // Suppression de l'effet de la roulette de la souris qui modifiait les valeurs dans le champ :
                  onWheel={(e) => {
                    e.target.blur(); // Retire le focus lorsque l'utilisateur utilise la molette
                    /* e.preventDefault(); */ // Empêche l'événement par défaut
                  }}
                  error={meta.error && meta.touched ? meta.error : null}
                  onChange={(event, data) => {
                    input.onChange(data.value)
                    this.updateTotalLigneHT(form)
                    this.updateTotalRemise(form)
                    this.updateTotalHT(form)
                    this.updateTotalMontantTVA(form)
                    this.updateLigneTTC(form)
                    //updateMontantRemiseIndicative(form)
                  }}
                />
              )
            }}
          </Field>
        </Table.Cell>}
        <Table.Cell>
          <Field
            name={`devisLignes[${index}].montantRemiseIndicative`}
            validate={composeValidators(isRequired)}
          >
            {({ input, meta }) => {
              const form = useForm()
              return (
                <SemanticForm.Input
                  {...input}
                  type="number"
                  className="in-array"
                  readOnly={false}
                  disabled={false}
                  width={100}
                  min="0"
                  // Suppression de l'effet de la roulette de la souris qui modifiait les valeurs dans le champ :
                  onWheel={(e) => {
                    e.target.blur(); // Retire le focus lorsque l'utilisateur utilise la molette
                    /* e.preventDefault(); */ // Empêche l'événement par défaut
                  }}
                  error={meta.error && meta.touched ? meta.error : null}
                  onChange={(event, data) => {
                    input.onChange(data.value)
                    this.updateTotalLigneHT(form)
                    this.updateTotalRemise(form)
                    this.updateTotalHT(form)
                    this.updateTotalMontantTVA(form)
                    this.updateLigneTTC(form)
                    //updateMontantRemiseIndicative(form)
                  }}
                />
              )
            }}
          </Field>
        </Table.Cell>

        {/*<Table.Cell>*/}
        {/*  <Field name={`devisLignes[${index}].parcelleId`}>*/}
        {/*    {({ input, meta }) => {*/}
        {/*      const form = useForm()*/}

        {/*      return (*/}
        {/*        <SemanticForm.Field>*/}
        {/*          <SemanticForm.Dropdown*/}
        {/*            {...input}*/}
        {/*            fluid*/}
        {/*            disabled={view}*/}
        {/*            selection*/}
        {/*            onBlur={(e) => e.preventDefault()}*/}
        {/*            options={this.state.parcelles?.map((parcelle) => {*/}
        {/*              return {*/}
        {/*                key: parcelle.id,*/}
        {/*                value: parcelle.id,*/}
        {/*                text: parcelle.code,*/}
        {/*              }*/}
        {/*            })}*/}
        {/*            onChange={(event, data) => {*/}
        {/*              console.log(data.value)*/}
        {/*              input.onChange(data.value)*/}
        {/*            }}*/}
        {/*            error={meta.error && meta.touched ? meta.error : null}*/}
        {/*          />*/}
        {/*        </SemanticForm.Field>*/}
        {/*      )*/}
        {/*    }}*/}
        {/*  </Field>*/}
        {/*</Table.Cell>*/}
        {/*<Table.Cell>*/}
        {/*  <Field*/}
        {/*    name={`devisLignes[${index}].emballageId`}*/}
        {/*    validate={composeValidators(isRequired)}*/}
        {/*  >*/}
        {/*    {({ input, meta }) => {*/}
        {/*      return (*/}
        {/*        <SemanticForm.Field>*/}
        {/*          <SemanticForm.Dropdown*/}
        {/*            {...input}*/}
        {/*            fluid*/}
        {/*            disabled={view}*/}
        {/*            selection*/}
        {/*            onBlur={(e) => e.preventDefault()}*/}
        {/*            options={this.props.emballages?.map((emballage) => {*/}
        {/*              return {*/}
        {/*                key: emballage.id,*/}
        {/*                value: emballage.id,*/}
        {/*                text: emballage.code,*/}
        {/*              }*/}
        {/*            })}*/}
        {/*            onChange={(event, data) => {*/}
        {/*              input.onChange(data.value)*/}
        {/*            }}*/}
        {/*            error={meta.error && meta.touched ? meta.error : null}*/}
        {/*          />*/}
        {/*        </SemanticForm.Field>*/}
        {/*      )*/}
        {/*    }}*/}
        {/*  </Field>*/}
        {/*</Table.Cell>*/}
        <Table.Cell>
          <Field
            name={`devisLignes[${index}].totalLigneHT`}
            validate={composeValidators(isRequired)}
          >
            {({ input, meta }) => {
              const form = useForm()
              return (
                <SemanticForm.Input
                  {...input}
                  type="number"
                  className="in-array"
                  readOnly={true}
                  width={100}
                  disabled={true}
                  error={meta.error && meta.touched ? meta.error : null}
                  onChange={(event, data) => {
                    input.onChange(data.value)
                  }}
                />
              )
            }}
          </Field>
        </Table.Cell>
        <Table.Cell>
          <Field
            name={`devisLignes[${index}].tauxDeTVA`}
            validate={composeValidators(isRequired)}
          >
            {({ input, meta }) => {
              const form = useForm()
              return (
                <SemanticForm.Input
                  {...input}
                  type="number"
                  className="in-array"
                  readOnly= {true}
                  width={100}
                  disabled={true}
                  onChange={(event, data) => {
                    input.onChange(data.value)
                  }}
                />
              )
            }}
          </Field>
        </Table.Cell>
        <Table.Cell>
          <Field 
            name={`devisLignes[${index}].totalLigneTTC`}
            validate={composeValidators(isRequired)}
          >
            {({ input, meta }) => {
              const form = useForm()
              return (
                <SemanticForm.Input
                  {...input}
                  type="number"
                  className="in-array"
                  readOnly={true}
                  width={100}
                  disabled={true}
                  error={meta.error && meta.touched ? meta.error : null}
                  onChange={(event, data) => {
                    input.onChange(data.value)
                  }}
                />
              )
            }}
          </Field>
        </Table.Cell>
        <Table.Cell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            type={'button'}
            icon="remove"
            color={'red'}
            disabled={view}
            onClick={() => {
              this.props.onDelete(index)
            }}
          />
        </Table.Cell>
      </Table.Row>
    )
  }
}

export default DevisFormLigne
