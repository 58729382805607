import React, { useState } from 'react';
import { Container } from 'semantic-ui-react';
import './Home.css';
import Login from '../login/Login';
import logo from '../../OccifactLogoW.png';
import istLogo from '../../iconeISTw.png';
import backgroundImage from '../../BackgroundlHP.png'

const Home = (props) => {
  const [ClickLogin, setClickLogin] = useState(false);

  const Log = (LogginIn, societeID) => {
    localStorage.setItem('loggedIn', JSON.stringify(LogginIn));
    localStorage.setItem('societeId', JSON.stringify(societeID));
    setClickLogin(false);
    props.log(true, societeID);
  };

  const onButtonClick = () => {
    setClickLogin(true);
  };

  return (
    <div className="backgroundImgHome" style={{ backgroundImage: `url(${backgroundImage})` }}>
      {!ClickLogin ? (
        <Container fluid>
          <div className="homepage-container">
            {/* Logo principal */}
            <div className="logo-container">
              <img src={logo} alt="OcciFact Logo" className="homepage-logo" />
            </div>
  
            <div className="middle-text">
              <p>Pour répondre aux exigences de la réforme de la facturation électronique,</p>
              <p>Inforsud Technologies a conçu <strong>OcciFact</strong>, une solution de facturation en </p>
              <p>SaaS, simple et économique à destination des TPE</p>
  
              {/* Bouton Log in */}
              <div className="homepage-buttonContainer">
                <input
                  className="homepage-inputButton"
                  type="button"
                  onClick={onButtonClick}
                  value="Log in"
                />
              </div>
            </div>
  
            {/* Logo IST en bas */}
            <footer className="homepage-footer">
              <div className="contact-text">
                <a href="https://inforsud-technologies.com/contact/" className="contact-link">Nous contacter</a>
              </div>
              <img src={istLogo} alt="IST Logo" />
            </footer>
          </div>
        </Container>
      ) : (
        <Login log={Log} />
      )}
    </div>
  );  
};

export default Home;