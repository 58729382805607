import React, { Fragment } from 'react'
import DevisForm from './DevisForm'
import { history } from '../../utils/history'
import { DocType } from '../../utils/enum-divers'
import { Button } from 'semantic-ui-react'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { toast } from 'react-toastify'
import { handleErrorResponse } from '../../utils/handle-error-response'
import moment from 'moment'
import CustomModal from '../shared/modal/CustomModal'
import EmailModal from '../shared/modal/EmailModal'
const _ = require('lodash');

class DevisCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultEmetteur: null,
      submitBtn: null,
      showModalPrint: false,
      id: 0,
      showModalMail: false,
      numDv: '',
      defaultEmail: '',
    }
  }

  componentDidMount() {
    ipcRenderer.invoke(Channels.CONTACT_FIND_ALL, {
      params: { isGerant: true },
      order: { nom: 'ASC' },
      take: 1,
    })
    .then(({ data }) => {
      if (data?.length > 0) {
        const [contact] = data
        const adresseAsString = `${contact.nom} \n${
          contact.complement ? contact.complement : ''
        }\n${contact.adresse}\n${contact.codePostal} ${contact.ville}\n${
          contact.pays.libelleFR
        }`
        this.setState({
          defaultEmetteur: {
            id: contact.id,
            asString: adresseAsString,
          },
        })
      }
    })
  }

  modalActionsMail() {
    const { type } = this.props.match.params

    return (
      <Fragment>
        <button className="ui button teal left labeled icon" type="submit">
          <i className="mail icon"></i>
          Envoyer
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalMail: false,
            })
            history.push(`/devis/${type}`)
            toast.success(
              `Le devis ${this.state.numDv} a bien été créé`
            )
          }}
        >
          Fermer
        </button>
      </Fragment>
    )
  }

  emailSubmit = (email, objet, message) => {
    const { type } = this.props?.match?.params

    ipcRenderer
      .invoke(Channels.EMAIL_SEND, {
        email,
        objet,
        message,
        typeDocument: 'Dv',
        idDocument: this.state.id,
        numero: this.state.numDv,
      })
      .then((response) => {
        if (response.error) {
          handleErrorResponse(response.error)
        } else {
          history.push(`/devis/${type}`)
          toast.success(
            `Le devis ${this.state.numDv} a bien été créé et l'email a bien été envoyé`
          )
        }
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  getRecepteurEmail = (id) => {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_BY_ID, id)
      .then((response) => {
        this.setState({
          defaultEmail: response.email,
        })
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  //RAF : Fonction "imprimer" : à ce jour, le pdf s'enregistre dans documents Storage et ne s'ouvre pas directement.
  modalActionsPrint(id) {
    const { type } = this.props.match.params

    return (
      <Fragment>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalPrint: false,
              })
          ipcRenderer
            .invoke(Channels.DEVIS_PRINT, {
              data: id,
              options: { printParcelles: true },
              })
              .then((response) => {
                console.log('API Response:', response);
                const pdfPath = response.filePath;
                if (pdfPath) {
                  window.open(pdfPath);  
                  toast.success(`Le devis a bien été créé`);
                } else {
                  toast.error('Le chemin du PDF est manquant ou invalide.');
                }
              })
            .catch((error) => {
              console.error(error)
              toast.error('Erreur lors de la création du devis.');
            })
          }}
        >
          <i className="checkmark icon" />
          Impression PDF
        </button>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalPrint: false,
            })
            console.log('Annulation de l\'impression du devis avec id ' + id) 
          }
          }
        >
          Non
        </button>
      </Fragment>
    )
  }

  getHeaderActions = () => {
    const { type } = this.props?.match?.params
    return (
      <Fragment>
        <Button
          icon="save"
          type="submit"
          content="Enregistrer"
          color="teal"
          onClick={() => {
            this.setState({ ...this.state, submitBtn: 'enregistrer' })
          }}
        />
        <Button
          icon="print"
          type="submit"
          content="Imprimer"
          color="green"
          onClick={() => {
            this.setState({ ...this.state, submitBtn: 'imprimer' })
          }}
        />
        <Button
          icon="mail"
          type="submit"
          content="Email"
          color="blue"
          onClick={() =>
            this.setState({
              submitBtn: 'email',
            })
          }
        />
        <Button
          icon="file alternate outline"
          type="submit"
          content="Facturer"
          color="red"
          onClick={() => {
            this.setState({ ...this.state, submitBtn: 'facturer' })
          }}
        />
        <Button
          icon="file alternate outline"
          type="submit"
          content="Livrer"
          color="orange"
          onClick={() => {
            this.setState({ ...this.state, submitBtn: 'livrer' })
          }}
        />
        <Button
          icon="reply"
          type="button"
          content="Retour à la liste"
          color="black"
          onClick={() => history.push(`/devis/${type}`)}
        />
      </Fragment>
    )
  }
  onFormSubmit = (formValues) => {
    const { type } = this.props.match.params
    if (!formValues.date) {
      toast.error('Veuillez renseigner une date correcte')
      return
    }
    ipcRenderer
      .invoke(Channels.DEVIS_CREATE, formValues)
      .then((response) => {
        if (response.hasError) {
          handleErrorResponse(response.errors)
        } else {
          if (this.state.submitBtn == 'facturer'){
            ipcRenderer
            .invoke(Channels.BON_DE_LIVRAISON_CREATE_FROM_DEVIS, response)
            .then((bon_de_livraison_response) => {
              if (bon_de_livraison_response.hasError) {
                handleErrorResponse(bon_de_livraison_response.errors)
              } else {
                toast.success(`Le bon de livraison ${bon_de_livraison_response.numero} a bien été créé`)

                //calcul de la quantité totale
                const lignes = formValues.devisLignes
                let quantite = _.sumBy(lignes, ({ poidsNet }) => {
                  if (!isNaN(poidsNet)) {
                    return parseFloat(parseFloat(poidsNet).toFixed(2))
                  } else {
                    return 0
                  }
                })
                // Construction de la facture à partir du Dv
                let facture = { isComptabilise:false,
                                isImprime: false,
                                date: bon_de_livraison_response.date, // RAF today
                                dateEcheance : moment(bon_de_livraison_response.date).add(30, 'days'),
                                emetteurId: bon_de_livraison_response.emetteurId,
                                emetteurAsString:bon_de_livraison_response.emetteurAsString,
                                recepteurId: bon_de_livraison_response.recepteurId,
                                recepteurAsString : bon_de_livraison_response.recepteurAsString,
                                type: bon_de_livraison_response.type,
                                mentions: bon_de_livraison_response.mentions,
                                bonsDeLivraisons: [{
                                  id: bon_de_livraison_response.id,
                                  numero: bon_de_livraison_response.numero,
                                  bonDeLivraisonLignes: bon_de_livraison_response.bonDeLivraisonLignes
                                }],
                                totalHTAvantRemise: bon_de_livraison_response.totalPrixHT + bon_de_livraison_response.totalMontantRemiseIndicative,
                                montantRemise: bon_de_livraison_response.totalMontantRemiseIndicative,
                                totalHTApresRemise: bon_de_livraison_response.totalPrixHT,
                                totalTVA_2_1: bon_de_livraison_response.totalTVA_2_1,
                                totalTVA_5_5: bon_de_livraison_response.totalTVA_5_5,
                                totalTVA_10: bon_de_livraison_response.totalTVA_10,
                                totalTVA_20: bon_de_livraison_response.totalTVA_20,
                                montantTVA: bon_de_livraison_response.totalTVA,
                                totalTTC: bon_de_livraison_response.totalTTC,
                                poidsNet: quantite,
                                dateDeReglement: null,
                                dateEncaissement: null,
                                totalCotisation: 0,
                                tauxDeTVA: 0,
                                remise: 0,
                              }
                ipcRenderer
                .invoke(Channels.FACTURE_CREATE, facture)
                .then((response_facture) => {
                  if (response_facture.hasError) {
                    handleErrorResponse(response_facture.errors)
                  } else {
                    history.push(`/factures/${type}/edit/${response_facture.id}`)
                    toast.success(`La facture ${response_facture.numero} a bien été créée`)
                  }
                })  
              }
            })
          }
          else if (this.state.submitBtn == 'imprimer') {
            this.setState({ id: response.id }, () => {
              this.setState({ showModalPrint: true })
              // TODO: Update isPrinted
            })
          } else if (this.state.submitBtn == 'email') {
            this.getRecepteurEmail(response.recepteurId)
            this.setState(
              {
                id: response.id,
                numDv: response.numero,
              },
              () =>
                this.setState({
                  showModalMail: true,
                })
            )
          } else if (this.state.submitBtn == 'livrer') {
            ipcRenderer
            .invoke(Channels.BON_DE_LIVRAISON_CREATE_FROM_DEVIS, response)
            .then((bon_de_livraison_response) => {
              if (bon_de_livraison_response.hasError) {
                handleErrorResponse(bon_de_livraison_response.errors)
              } else {
                history.push(`/bons-de-livraison/${type}/edit/${bon_de_livraison_response.id}`)
                toast.success(`Le bon de livraison ${bon_de_livraison_response.numero} a bien été créé`)
              }
            })
          } else {
            history.push(`/devis/${type}`)
            toast.success(
              `Le devis ${response.numero} a bien été créé`
            )
          }
        }
      })
      .catch((error) => {
        console.log(error)
        handleErrorResponse(error)
      })
  }

  render() {
    const { type } = this.props.match.params
    const { defaultEmetteur, defaultEmail, id, numDv } = this.state
    let actualDate = new Date()
    const initialValue = {
      type: type === DocType.ACHAT ? DocType.ACHAT : DocType.VENTE,
      isFactureDone: false,
      montantRemiseIndicative: 0,
      date: moment(actualDate, 'DD-MM-YYYY'),
    }
    if (defaultEmetteur) {
      initialValue.emetteurId = defaultEmetteur.id
      initialValue.emetteurAsString = defaultEmetteur.asString
    }
      return (
      <Fragment>
        <DevisForm
          /* formTitle={`Créer un devis - ${type === DocType.ACHAT ? 'Achats' : 'Ventes'} `} */
          formTitle={`Créer un devis`}
          type={type === DocType.ACHAT ? DocType.ACHAT : DocType.VENTE}
          headerActions={this.getHeaderActions()}
          onSubmit={this.onFormSubmit}
          initialValues={initialValue}
        />
        <EmailModal
          title="Envoi du devis par mail"
          visible={this.state.showModalMail}
          actions={this.modalActionsMail()}
          id={id}
          numero={numDv}
          email={defaultEmail}
          typeDocument="Dv"
          onSubmit={this.emailSubmit}
        />
        <CustomModal
          title="Imprimer"
          content="Imprimer le devis"
          visible={this.state.showModalPrint}
          actions={this.modalActionsPrint(this.state.id)}
        />
      </Fragment>
    )
  }
}

export default DevisCreate
