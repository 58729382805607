import { facture } from '../actions/types'
const _ = require('lodash');

export default (
  state = {
    facture: null,
    byIds: {},
    count: 0,
    ids: [],
    query: {
      skip: 0,
      take: 10,
      params: {
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
      order: { id: 'ASC' },
    },
    queryFaAchat: {
      skip: 0,
      take: 10,
      params: {
        type: 'ACHAT',
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
      order: { id: 'ASC' },
      type: 'ACHAT',
    },
    queryFaVente: {
      skip: 0,
      take: 10,
      params: {
        type: 'VENTE',
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
        notPaye: true,
        notComptabilise:true,
        isPaye: true,
        isComptabilise: true,
        isEncaissee: true,
        notEncaissee: true, 
      },
      order: { id: 'ASC' },
    },
  },
  action
) => {
  switch (action.type) {
    case facture.FETCH_FACTURE:
      //console.log(action.payload)
      return { ...state, facture: action.payload }
    case facture.FETCH_FACTURES:
      const faState = {
        byIds: _.mapKeys(action.payload.response.data, 'id'),
        count: action.payload.response.count,
        ids: _.map(action.payload.response.data, 'id'),
        query: action.payload.query,
        queryFaAchat:
          action.payload.query.type?.params?.type === 'ACHAT'
            ? action.payload.query
            : state.queryFaAchat,
        queryFaVente:
          action.payload.query?.params?.type === 'VENTE'
            ? action.payload.query
            : state.queryFaVente,
      }
      return {
        ...state,
        ...faState,
      }
    default:
      return state
  }
}
