import React from 'react'
import SubHeader from '../../shared/subHeader/SubHeader'
import DataTables from '../../shared/datatable/Datatable'
import { connect } from 'react-redux'
import { fetchStatsAchatsVentes } from '../../../redux/actions/statistiques-action'
import { cellFormat, roundTwoDec } from '../../shared/datatable/cell-format'
import Pagination from '../../shared/datatable/Pagination'
import AchatsVentesSearchForm from './AchatsVentesSearchForm'
import { Channels } from '../../../api/channels'
import { Divider } from 'semantic-ui-react'
import ExportPdf from '../../shared/exportcsv/export-pdf'
import { ipcRenderer } from '../../../api/api'
import moment from 'moment'
const _ = require('lodash');

class StatistiquesAchatsVentes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cols: [
        {
          text: 'Code produit',
          key: 'codeProduit',
        },
        {
            text: 'Produit',
            key: 'libelleProduit',
        },
        //{
        //  text: 'Poids-net achat',
        //  key: 'poidsNetAchat',
        //  format: cellFormat.NUMBER,
        //},
        {
            _text: 'Poids-net vente',
            text: 'Quantité totale',
          key: 'poidsNetVente',
          format: cellFormat.NUMBER,
        },
        //{
        //  text: 'Total achat',
        //  key: 'totalAchat',
        //  format: cellFormat.MONEY,
        //},
        {
          text: 'Prix total',
          key: 'totalVente',
          format: cellFormat.MONEY,
        },
        //{
        //  text: 'Moyenne achat',
        //  key: 'moyenneAchat',
        //  format: cellFormat.MONEY,
        //},
        {
          text: 'Prix moyen',
          key: 'moyenneVente',
          format: cellFormat.MONEY,
        },
      ],
    }
  }
  componentDidMount() {
    const { query } = this.props.statistiques
    //console.log(query)
    this.props.fetchStatsAchatsVentes(query)
  }
  /**
   * Action on page click
   * @param {*} pageInfo
   */
  onPageClick = (pageInfo) => {
    let query = this.props.statistiques.query
    query.skip = (pageInfo.currentPage - 1) * query.take
    this.props.fetchStatsAchatsVentes(this.props.statistiques.query)
  }
  onColumnClick = (col) => {
    let { query } = this.props.statistiques
    // Si on tri déjà par ce champs
    if (query.order[col]) {
      // Si le tri est déjà en ASC, on switch en DESC
      if (query.order[col] === 'ASC') {
        query.order[col] = 'DESC'
        // Sinon ASC
      } else {
        query.order[col] = 'ASC'
      }
    } else {
      query.order = { [col]: 'ASC' }
    }
    this.props.fetchStatsAchatsVentes(query)
  }

  /* Specifique au searchForm */
  onSubmitSearch = (formValues) => {
    const query = this.props.statistiques.query
    query.params = formValues
    query.skip = 0
    query.take = 10
    this.props.fetchStatsAchatsVentes(query)
  }

  onResetSearch = () => {
    const query = {
      skip: 0,
      take: 10,
      params: {
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
      order: {
        produitId: 'ASC',
      },
    }
    this.props.fetchStatsAchatsVentes(query)
  }

  renderTotalLine = (totaux) => {
    return (
        <tr className="total-row">
        <td></td>
        <td>Total</td>
{/*        <td>{totaux ? roundTwoDec(totaux.totalPdsNetAchat) : 0.0} Kg</td>*/}
        <td>{totaux ? roundTwoDec(totaux.totalPdsNetVente) : 0.0}{/* Kg*/}</td>
{/*        <td>{totaux ? roundTwoDec(totaux.totalAchat) : 0.0} €</td>*/}
        <td>{totaux ? roundTwoDec(totaux.totalVente) : 0.0} €</td>
{/*        <td>{totaux ? roundTwoDec(totaux.moyenneAchat) : 0.0} €</td>*/}
        <td>{totaux ? roundTwoDec(totaux.moyenneVente) : 0.0} €</td>
      </tr>
    )
  }
  getDataForExport = async () => {
    const { query } = this.props.statistiques
    const { data } = await ipcRenderer.invoke(
      Channels.STATS_ACHATS_VENTES,
      _.omit(query, ['skip', 'take'])
    )

    data.raws.push({
      codeProduit: 'Total',
      poidsNetVente: roundTwoDec(data.total.totalPdsNetVente)/* + 'kg'*/,
 /*     poidsNetAchat: roundTwoDec(data.total.totalPdsNetAchat) + 'kg',*/
      totalVente: roundTwoDec(data.total.totalVente) + '€',
  /*    totalAchat: roundTwoDec(data.total.totalAchat) + '€',*/
     /* moyenneAchat: roundTwoDec(data.total.moyenneAchat) + '€',*/
      moyenneVente: roundTwoDec(data.total.moyenneVente) + '€',
    })

    return data.raws
  }

  async generateFileName() {
    const { query } = this.props.statistiques
    const { params } = query
    let filename = 'Statistiques_Vente_'
    filename += `${
      query.params.fromDate
        ? '_du_' + moment(query.params.fromDate).format('DD/MM/YYYY')
        : ''
    }`
    filename += `${
      params.toDate ? '_au_' + moment(params.toDate).format('DD/MM/YYYY') : ''
    }`
    filename += `${params.codeFamille ? '_CF_' + params.codeFamille : ''}`
    filename += `${params.codeVariete ? '_CV_' + params.codeVariete : ''}`
    filename += `${params.codeProduit ? '_CP_' + params.codeProduit : ''}`
    return filename
  }

  getHeaderActions = () => {
    return (
      <ExportPdf
        filenameGeneratorFunc={async () => await this.generateFileName()}
        csvData={this.getDataForExport}
        headers={this.state.cols}
        orientation="portrait"
      />
    )
  }

  render() {
    const { statistiques } = this.props
    return (
      <div className="ui content">
        <div className="ui segment">
          <SubHeader
            title="Statistiques ventes"
            actions={this.getHeaderActions()}
          />
          <Divider />
          <div className="ui content">
            <AchatsVentesSearchForm
              onSubmit={this.onSubmitSearch}
              initialValues={statistiques?.query?.params}
              onResetSearch={this.onResetSearch}
            />

            <DataTables
              columns={this.state.cols}
              rows={statistiques?.data?.raws}
              onColumnClick={this.onColumnClick}
              actionRow={this.getActionRow}
              query={statistiques?.query}
              total={this.renderTotalLine(statistiques?.data?.total)}
            />
          </div>
          <Pagination
            onPageClick={this.onPageClick}
            itemPerPage={statistiques?.query.take}
            pageNeighbours={1}
            totalItems={statistiques?.count}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log(state)
  return {
    statistiques: {
      count: state.statistiques.achatsVentesCount,
      data: state.statistiques.achatsVentes,
      query: state.statistiques.achatsVentesQuery,
    },
  }
}

export default connect(mapStateToProps, { fetchStatsAchatsVentes })(
  StatistiquesAchatsVentes
)
