import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Card, Feed, Grid, Label, Statistic, Table } from 'semantic-ui-react'
import { fetchDesDevis } from '../../redux/actions/devis-action'
class DevisRecap extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let query = this.props.listeDevis.query
    query = {
      ...query,
      params: {
        fromDate: moment().startOf('year').toDate(),
        toDate: moment().add(7, 'days').startOf('day').toDate(),
          isFactureDone: 0,
          type: 'VENTE',
      },
      order: {
        date: 'ASC',
      },
      }
    this.props.fetchDesDevis(query)
  }

  renderTableRecap() {
    const { listeDevis } = this.props
    const lignes = listeDevis.data.map((dv, i) => {
      return (
        <Table.Row key={i}>
          <Table.Cell>{dv.numero}</Table.Cell>
          <Table.Cell>{dv.type}</Table.Cell>
          <Table.Cell>{dv.totalPrixHT} € </Table.Cell>
        </Table.Row>
      )
    })
    return lignes
  }

  render() {
    const { listeDevis } = this.props
    //console.log(listeDevis)
    const nbEnAttente = listeDevis.count
    const ligneDV = []
    for (var i = 0; i < nbEnAttente; i++) {
      ligneDV.push(`<Grid.Row divided>
                      <Grid.Column width={4}>${listeDevis?.data?.[i]?.numero}</Grid.Column>
                      <Grid.Column width={12}>${listeDevis?.data?.[i]?.n}</Grid.Column>
                    </Grid.Row>`)
    }
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>Devis</Card.Header>
        </Card.Content>
        <Card.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Statistic size="tiny">
                  <Statistic.Label>ATTENTE</Statistic.Label>
                  <Statistic.Value>{nbEnAttente}</Statistic.Value>
                </Statistic>
              </Grid.Column>
              <Grid.Column width={12}>
                Devis en attente de retour
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Table basic="very" celled>
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell>N°</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Prix</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderTableRecap()}</Table.Body>
          </Table>
        </Card.Content>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    listeDevis: {
      count: state.listeDevis.count,
      data: state.listeDevis.ids
        ? state.listeDevis.ids.map((id) => {
            return state.listeDevis.byIds[id]
          })
        : [],
      query: state.listeDevis.query,
    },
  }
}

export default connect(mapStateToProps, { fetchDesDevis })(
  DevisRecap
)
