import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Button, Label } from 'semantic-ui-react'
import SubHeader from '../shared/subHeader/SubHeader'
import { Table } from 'semantic-ui-react'
import { VisionMode } from '../../utils/theme'
import { history } from '../../utils/history'
import { NavLink } from 'react-router-dom'
import { fetchProduit } from '../../redux/actions/produit-action'
const _ = require('lodash');

class ProduitView extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.fetchProduit(this.props.match.params.id)
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button
          icon="reply"
          type="submit"
          content="Retour à la liste"
          color="black"
          onClick={() => {
            history.push('/settings/produits')
          }}
        />
      </Fragment>
    )
  }

  render() {
    const { produit } = this.props
    return (
      <div className="ui content">
        <div className="ui segment">
          <SubHeader
            title={`Produit ${produit?.libelle}`}
            actions={this.getHeaderActions()}
          />
          <div className="ui divider"></div>
          <div className="dt-top-container">
            <div className="content">
              <Table
                definition
                className={`${
                  this.props.theme.visionMode === VisionMode.AMELIORE
                    ? 'inverted'
                    : ''
                }`}
              >
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Code</Table.Cell>
                    <Table.Cell>{produit?.code}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Libellé</Table.Cell>
                    <Table.Cell>{produit?.libelle}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Description</Table.Cell>
                    <Table.Cell>{produit?.description}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Catégorie</Table.Cell>
                    <Table.Cell>
                      {produit?.variete ? (
                        <Label size="medium" as="a" color="black">
                          {produit.variete.code} - {produit.variete.libelle}
                        </Label>
                      ) : (
                        'Pas de catégorie'
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Compte comptable</Table.Cell>
                    <Table.Cell>
                      {produit?.compteComptable ? (
                        <Label size="medium" as="a" color="black">
                          {produit.compteComptable.numero} - {produit.compteComptable.intitule}
                        </Label>
                      ) : (
                        'Pas de compte comptable'
                      )}
                    </Table.Cell>
                  </Table.Row>
                  {/* <Table.Row>
                    <Table.Cell>Stock</Table.Cell>
                    <Table.Cell>{produit?.stock}</Table.Cell>
                  </Table.Row> */}
                    <Table.Row>
                    <Table.Cell>Prix HT</Table.Cell>
                    <Table.Cell>{produit?.prixHT}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>TVA</Table.Cell>
                    <Table.Cell>{produit?.tauxDeTVA}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return { produit: state.produits.produit, theme: state.theme }
}

export default connect(mapStateToProps, { fetchProduit })(ProduitView)
