import { devis, famille } from '../actions/types'
const _ = require('lodash');

export default (
  state = {
    devis: null,
    byIds: {},
    count: 0,
    ids: [],
    query: {
      skip: 0,
      take: 10,
      params: {},
      order: { id: 'ASC' },
    },
    queryDVAchat: {
      skip: 0,
      take: 10,
      params: {
        isFactureDone: false,
        type: 'ACHAT',
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
      order: { id: 'ASC' },
      type: 'ACHAT',
    },
    queryDVVente: {
      skip: 0,
      take: 10,
      params: {
        isFactureDone: false,
        type: 'VENTE',
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
      order: { id: 'ASC' },
    },
  },
  action
) => {
  switch (action.type) {
    case devis.FETCH_UN_DEVIS:
      return { ...state, devis: action.payload }
    case devis.FETCH_DES_DEVIS:
      const dvState = {
        byIds: _.mapKeys(action.payload.response.data, 'id'),
        count: action.payload.response.count,
        ids: _.map(action.payload.response.data, 'id'),
        query: action.payload.query,
        queryDVAchat:
          action.payload.query.type?.params?.type === 'ACHAT'
            ? action.payload.query
            : state.queryDVAchat,
        queryDVVente:
          action.payload.query?.params?.type === 'VENTE'
            ? action.payload.query
            : state.queryDVVente,
      }
      return {
        ...state,
        ...dvState,
      }
    default:
      return state
  }
}
