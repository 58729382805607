import React, { Component } from 'react'
import { Form as FinalForm, Field, useForm } from 'react-final-form'
import {
  Divider,
  Form as SemanticForm,
  Grid,
  Icon,
  Table,
} from 'semantic-ui-react'
import SubHeader from '../shared/subHeader/SubHeader'
import {
  composeValidators,
  minLength,
  maxLength,
  isRequired,
  mustBeNumber,
} from '../../utils/validators'
import arrayMutators from 'final-form-arrays'

import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import tauxDeTVA from '../../api/enum/taux-tva'
import { updateAvoirForm } from './avoir-form-updater'
import DatePicker from 'react-datepicker'
import moment from 'moment/moment'
import { SectionBonsDeLivraisons } from '../facture/partials/bl/SectionBonsDeLivraisons'
import { updateGlobal } from '../facture/partials/common-form-update'
import { FieldArray } from 'react-final-form-arrays'
import { BonDeLivraisonItemTableRow } from '../facture/partials/bl/BonDeLivraisonItemTableRow'
import { AvoirFormFooter } from './partials/AvoirFormFooter'
import { toast } from 'react-toastify'
const _ = require('lodash');

class AvoirForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      produits: [],
      factures: [],
      factureSelected: null,
      view: this.props.initialValues?.isComptabilise || this.props.view,
      initialValues: this.props.initialValues,
      totalTVA_2_1_visible: this.props.initialValues?.totalTVA_2_1 && this.props.initialValues.totalTVA_2_1!=0,
      totalTVA_5_5_visible: this.props.initialValues?.totalTVA_5_5 && this.props.initialValues.totalTVA_5_5!=0,
      totalTVA_10_visible: this.props.initialValues?.totalTVA_10 && this.props.initialValues.totalTVA_10!=0,
      totalTVA_20_visible: this.props.initialValues?.totalTVA_20 && this.props.initialValues.totalTVA_20!=0
    }
  }

  componentDidMount() {
    const query = {
      params: {
        type: 'VENTE',
        fromDate: moment().startOf('year').toDate(),
        toDate: moment().add(7, 'days').startOf('day').toDate(),
      },
    }

    ipcRenderer.invoke(Channels.PRODUIT_FIND_ALL, {}).then(({ data }) => {
      this.setState({
        produits: data,
      })
    })
    ipcRenderer.invoke(Channels.FACTURE_FIND_ALL, query).then(({ data }) => {
      //console.log(data)
      this.setState({
        factures: data,
      })
    }) 
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      const initialValues = this.props.initialValues
      this.setState({totalTVA_2_1_visible: initialValues.totalTVA_2_1 != 0,
        totalTVA_5_5_visible: initialValues.totalTVA_5_5 != 0, 
        totalTVA_10_visible: initialValues.totalTVA_10 != 0, 
        totalTVA_20_visible: initialValues.totalTVA_20 != 0})
    }
  }

  handleSearchChange = (e) => {
    if (e.target.value && e.target.value.length > 2) {
      const query = {
        params: {
          libelle: e.target.value,
        },
      }
      ipcRenderer.invoke(Channels.PRODUIT_FIND_ALL, query).then(({ data }) => {
        this.setState({
          produits: data,
        })
      })
    }
  }

  onSubmit = (formValues) => {
    if (!moment(formValues?.date).isValid() || !formValues?.date) {
      toast.error('La date est obligatoire.')
      return
    }
    if (formValues.date) {
      if (typeof formValues.date === 'number') {
        formValues.date = moment(formValues.date, 'DD/MM/YYYY')
        .hour(0)
        .minutes(0)
        .second(0)
        .toDate()
      }
    }

    if (!formValues.totalTVA_2_1) {
      formValues.totalTVA_2_1 = 0
    } else {
      formValues.totalTVA_2_1 = parseFloat(formValues.totalTVA_2_1)
    }
    if (!formValues.totalTVA_5_5) {
      formValues.totalTVA_5_5 = 0
    } else {
      formValues.totalTVA_5_5 = parseFloat(formValues.totalTVA_5_5)
    }
    if (!formValues.totalTVA_10) {
      formValues.totalTVA_10 = 0
    } else {
      formValues.totalTVA_10 = parseFloat(formValues.totalTVA_10)
    }
    if (!formValues.totalTVA_20) {
      formValues.totalTVA_20 = 0
    } else {
      formValues.totalTVA_20 = parseFloat(formValues.totalTVA_20)
    }

    formValues.totalMontantRemiseIndicative = formValues.totalMontantRemiseIndicative
    ? parseFloat(formValues.totalMontantRemiseIndicative)
    : 0

    this.props.onSubmit(formValues)
  }

  render() {
    const tvaOptions = []
    const { initialValues } = this.props
    for (const tva in tauxDeTVA) {
      if (!isNaN(Number(tva))) {
        tvaOptions.push({
          key: tauxDeTVA[tva],
          value: tva,
          text: `${tauxDeTVA[tva]} (${tva}%)`,
        })
      }
    }
    return (
      <FinalForm
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize={true}
        mutators={{
          ...arrayMutators,
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          },
        }}
        render={({ handleSubmit, form, values }) => {
          return (
            <SemanticForm onSubmit={handleSubmit} size="massive">
              <div className="ui content">
                <div className="ui segment">
                  <SubHeader
                    title={this.props.formTitle}
                    actions={this.props.headerActions}
                  />
                </div>
                <div className="ui segment">
                  <SemanticForm.Group widths="equal">
                    <Field
                      name="numero"
                      validate={composeValidators(
                        isRequired,
                        minLength(3),
                        maxLength(20)
                      )}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Numéro"
                          type="text"
                          placeholder="ex: AV-{NUMERO FACTURE}"
                          autoComplete="off" 
                          readOnly={this.props.isEdit || this.state.view}
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                    <Field name="date">
                      {({ input, meta }) => (
                        <SemanticForm.Field>
                          <label>Date </label>
                          <DatePicker
                            {...input.value}
                            locale="fr"
                            readOnly={this.state.view}
                            selected={
                              (input.value && new Date(input.value)) || null
                            }
                            onSelect={(date) => {
                              input.onChange(date)
                            }}
                            onChange={(date) => {
                              input.onChange(date)
                            }}
                            dateFormat="dd/MM/yyyy"
                          />
                        </SemanticForm.Field>
                      )}
                    </Field>
                    <Field
                      name="factureId"
                      validate={composeValidators(isRequired)}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Field>
                          <SemanticForm.Dropdown
                            {...input}
                            placeholder="Sélectionnez une facture"
                            autoComplete="off" 
                            fluid
                            search
                            disabled={this.props.isEdit || this.state.view}
                            selection
                           /*  onBlur={(e) => e.preventDefault()} */
                            options={this.state.factures.map((fa) => {
                              return {
                                key: fa.id,
                                value: fa.id,
                                text: `${fa.numero} ${
                                  fa.recepteur
                                    ? '- Code contact: ' + fa.recepteur.code
                                    : ''
                                }`,
                              }
                            })}
                            label="Facture"
                            onChange={async (event, data) => {
                              // event.preventDefault()
                              await ipcRenderer
                                .invoke(Channels.FACTURE_FIND_BY_ID, data.value)
                                 .then(async (data) => {
                                  // Ligne bl chargé avec le prix facturé et quantité 0
                                  /*data.bonsDeLivraisons.map((bl) =>
                                    bl.bonDeLivraisonLignes.map((ligneBl) => {
                                      ligneBl.poidsBrut = 0
                                      ligneBl.poidsNet = 0
                                      ligneBl.totalLigneHT = 0
                                      ligneBl.montantRemiseIndicative = 0
                                    })
                                  ) */ //CHRISTEL AVOIR
                                   this.setState({
                                    factureSelected: data,
                                    initialValues: {
                                      ...this.state.initialValues,
                                      numero: `AV-${data.numero}`,
                                    },
                                  }) 
                                  form.change('numero', `AV-${data.numero}`)
                                  form.change('tauxDeTVA', data.tauxDeTVA)
                                  form.change('bonsDeLivraisons', data.bonsDeLivraisons)
                                  await updateGlobal(form)
                                 
                                  const allBLLines = _.flatMap(
                                    _.get(form.getState().values, 'bonsDeLivraisons'),
                                    (item) => _(item.bonDeLivraisonLignes).value()
                                  )
                                  console.log(allBLLines)

                                  // Calcul du montant total des remises
                                  const totalRemises = _.sumBy(allBLLines, ({ montantRemiseIndicative, prixUnitaireHT, poidsNet }) => {
                                    const montantRemise = poidsNet * prixUnitaireHT * montantRemiseIndicative /100;
                                    return !isNaN(montantRemise) ? parseFloat(montantRemise.toFixed(2)) : 0;
                                  });

                                   form.change('totalMontantRemiseIndicative', totalRemises)

                                   let totalTVA_2_1 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
                                    if (!isNaN(totalLigneHT) && tauxDeTVA == 2.1) {
                                      return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
                                    } else {
                                      return 0
                                    }
                                  })
                              
                                  let totalTVA_5_5 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
                                    if (!isNaN(totalLigneHT) && tauxDeTVA == 5.5) {
                                      return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
                                    } else {
                                      return 0
                                    }
                                  })
                              
                                  let totalTVA_10 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
                                    if (!isNaN(totalLigneHT) && tauxDeTVA == 10) {
                                      return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
                                    } else {
                                      return 0
                                    }
                                  })
                              
                                  let totalTVA_20 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
                                    if (!isNaN(totalLigneHT) && tauxDeTVA == 20) {
                                      return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
                                    } else {
                                      return 0
                                    }
                                  })

                                  this.setState({totalTVA_2_1_visible: totalTVA_2_1 != 0,
                                    totalTVA_5_5_visible: totalTVA_5_5!=0, 
                                    totalTVA_10_visible: totalTVA_10!=0, 
                                    totalTVA_20_visible: totalTVA_20!=0})
                                })

                              return input.onChange(data.value)
                            }}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                      )}
                    </Field>
                  </SemanticForm.Group>

                  <SemanticForm.Group widths="equal">
                    <Field name="isPrixEnBaisse" type="checkbox">
                      {({ input, meta }) => (
                        <SemanticForm.Checkbox
                          {...input}
                          toggle
                          disabled={this.state.view}
                          onChange={(event, { checked }) => {
                            return input.onChange(checked)
                          }}
                          label="Prix en baisse"
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                    <Field name="isRetourMarchandise" type="checkbox">
                      {({ input, meta }) => (
                        <SemanticForm.Checkbox
                          {...input}
                          toggle
                          disabled={this.state.view}
                          onChange={(event, { checked }) => {
                            return input.onChange(checked)
                          }}
                          label="Retour de marchandise"
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                    <Field name="isPerteMarchandise" type="checkbox">
                      {({ input, meta }) => (
                        <SemanticForm.Checkbox
                          {...input}
                          toggle
                          disabled={this.state.view}
                          onChange={(event, { checked }) => {
                            return input.onChange(checked)
                          }}
                          label="Perte de marchandise"
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                    {/* <Field name="isComptabilise" type="checkbox">
                      {({ input, meta }) => (
                        <SemanticForm.Checkbox
                          {...input}
                          toggle
                          disabled={this.state.view}
                          onChange={(event, { checked }) => {
                            return input.onChange(checked)
                          }}
                          label="Comptabilisé"
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field> */}
                  </SemanticForm.Group>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Divider horizontal>
                          <h4>
                            <Icon name="bar chart" />
                            Lignes avoir
                          </h4>
                        </Divider>
                        {this.state.factureSelected != null ? (
                          <SectionBonsDeLivraisons
                            type={'avoir'}
                            values={this.state.factureSelected}
                            view={this.state.view}
                            avoir={true}
                            onUpdateTVA={(totalTVA_2_1_visible, totalTVA_5_5_visible, totalTVA_10_visible, totalTVA_20_visible)=>{
                              this.setState({totalTVA_2_1_visible: totalTVA_2_1_visible,
                                totalTVA_5_5_visible: totalTVA_5_5_visible, 
                                totalTVA_10_visible: totalTVA_10_visible, 
                                totalTVA_20_visible: totalTVA_20_visible})}}
                          />
                        ) : (
                          <div>
                            <Table celled compact>
                              <Table.Header>
                                <Table.Row>
                                  {/* <Table.HeaderCell>Colis</Table.HeaderCell> */}
                                  <Table.HeaderCell width={1}>Code</Table.HeaderCell>
                                  <Table.HeaderCell width={3}>Désignation</Table.HeaderCell>
                                  <Table.HeaderCell width={5} colSpan="2">Description</Table.HeaderCell>
                                 {/* <Table.HeaderCell>CAT</Table.HeaderCell>*/}
                                  <Table.HeaderCell width={1}>Quantité</Table.HeaderCell>
                                  {/*<Table.HeaderCell>Tare</Table.HeaderCell>*/}
                                  {/*<Table.HeaderCell>Pds Net</Table.HeaderCell>*/}
                                  <Table.HeaderCell width={1}>Prix Unitaire</Table.HeaderCell>
                                  <Table.HeaderCell width={1}>Taux Remise</Table.HeaderCell>
                                  <Table.HeaderCell width={1}>Total HT</Table.HeaderCell>
                                  <Table.HeaderCell width={1}>Taux TVA</Table.HeaderCell>
                                  <Table.HeaderCell width={1}>Total TTC</Table.HeaderCell>
                                  <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <FieldArray name={`avoirLignes`}>
                                {({ fields }) => {
                                  return (
                                    <Table.Body>
                                      {fields.map((name, index) => {
                                        return (
                                          <BonDeLivraisonItemTableRow
                                            name={name}
                                            parentName={name}
                                            index={index}
                                            key={index}
                                            view={this.state.view}
                                            avoir={true}
                                            removeLine={() => {
                                               fields.remove(index) 
                                            }}
                                            onUpdateTVA={(totalTVA_2_1_visible, totalTVA_5_5_visible, totalTVA_10_visible, totalTVA_20_visible)=>{
                                              this.setState({totalTVA_2_1_visible: totalTVA_2_1_visible,
                                                totalTVA_5_5_visible: totalTVA_5_5_visible, 
                                                totalTVA_10_visible: totalTVA_10_visible, 
                                                totalTVA_20_visible: totalTVA_20_visible})}}
                                          />
                                        )
                                      })}
                                    </Table.Body>
                                  )
                                }}
                              </FieldArray>
                            </Table>
                          </div>
                          )}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                        <Grid.Column>
                        <Divider horizontal>
                          <h4>
                            <Icon name="bar chart" />
                            Totaux
                          </h4>
                        </Divider>
                       
                         <AvoirFormFooter 
                         TVA_2_1_visible= {this.state.totalTVA_2_1_visible}
                         TVA_5_5_visible= {this.state.totalTVA_5_5_visible}
                         TVA_10_visible= {this.state.totalTVA_10_visible}
                         TVA_20_visible= {this.state.totalTVA_20_visible}/>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
                <div className="ui segment">
                    <SubHeader
                        title={''}
                        actions={this.props.headerActions}
                    />
                </div>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default AvoirForm
