import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { Button, Grid, Message } from 'semantic-ui-react'
import { ipcRenderer } from '../../../../api/api'
import { Channels } from '../../../../api/channels' 
import { Field, useForm } from 'react-final-form'
import { BonDeLivraisonItem } from './BonDeLivraisonItem'
import { SelectBonDeLivraison } from './SelectBonDeLivraison'
import { updateGlobal } from '../common-form-update'
const _ = require('lodash');

export class SectionBonsDeLivraisons extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      availableBLs: [],
      familleValid: new Map(),
    }

     
  }
  componentDidMount() {
    if (this.props.values) {
      this.setState({
        availableBLs: this.props.values.bonsDeLivraisons,
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.values !== this.props.values) {
      if (this.props.values.emetteurId && this.props.values.recepteurId) {
        ipcRenderer
          .invoke(Channels.BON_DE_LIVRAISON_FIND_ALL, {
            params: {
              emetteurId: this.props.values.emetteurId,
              recepteurId: this.props.values.recepteurId,
              type: this.props.values.type,
              //isFactureDone: false, // Récupère les BLs ne faisant pas l'objet de facturation
            },
          })
          .then(({ data }) => {
            this.setState({
              availableBLs: data,
            })
          })
      }
    }
  }

  ConditionHasValue = ({ when, children }) => (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value ? children : null)}
    </Field>
  )

  ConditionHasNoValue = ({ when, children }) => (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value ? null : children)}
    </Field>
  )

  onChangeBLInvoice = (form) => {
   /* try {
       const familleValid = this.state.familleValid
      const familles = _(form.getState().values.bonsDeLivraisons)
        .flatMap((item) => item.bonDeLivraisonLignes)
        .map((item) => item.produit?.variete?.famille)
        .uniqBy((item) => item.id)
        .value()
      //console.log(familles)
      if (familles.length > 1) {
        familleValid.set(
          'famille',
          `Plusieurs familles sont sur cette facture. Code des familles présentes: ${_.map(
            familles,
            (fam) => fam.code
          ).join(', ')}`
        )
      } else {
        familleValid.delete('famille')
      }
      this.setState({
        familleValid: familleValid,
      })
    } catch (error) {
      console.log(error)
    } */
  }

  render() {
    const availblesBLs = this.state.availableBLs
    const idFacture = this.props.values?.id
    const { view , avoir, isTTC} = this.props
    return (
      <FieldArray name="bonsDeLivraisons">
        {({ fields }) => {
          return (
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Message
                    hidden={this.state.familleValid.size <= 0}
                    icon="warning sign"
                    header="Certains éléments peuvent être incorrects"
                    list={[...this.state.familleValid.values()]}
                  />
                </Grid.Column>
              </Grid.Row>

              {fields?.map((name, index) => {
                const form = useForm()
                return (
                  <Grid.Row key={index} centered>
                    <Grid.Column width={16} textAlign={'center'}>
                      <Grid centered>
                        <Grid.Row>
                          <Grid.Column width={4} textAlign={'center'}>
                            {/* Dropdown ajout de BLs visible uniquement pour les nouvelles factures */}
                            <this.ConditionHasNoValue
                              when={`${name}.id`}
                              type="hasValue"
                            >
                              <SelectBonDeLivraison
                                availableBLs={availblesBLs}
                                fields={fields}
                                name={`${name}.id`}
                                index={index}
                                idFacture={idFacture}
                                onChangeBLInvoice={this.onChangeBLInvoice}
                                onUpdateTVA={this.props.onUpdateTVA}
                              />
                            </this.ConditionHasNoValue>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <this.ConditionHasValue
                              when={`${name}.id`}
                              type="hasValue"
                            >
                              <BonDeLivraisonItem
                                parentName={name}
                                view={view}
                                avoir={avoir}
                                isTTC={isTTC}
                                 onUpdateTVA={this.props.onUpdateTVA}
                              />
                            </this.ConditionHasValue>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <Button
                              type={'button'}
                              content="Supprimer"
                              size="mini"
                              disabled={this.props.view}
                              negative
                              onClick={async () => {
                                fields.remove(index)
                                this.onChangeBLInvoice(form)
                                await updateGlobal(form)
                                const allBLLines = _.flatMap(
                                  _.get(form.getState().values, 'bonsDeLivraisons'),
                                  (item) => _(item.bonDeLivraisonLignes).value()
                                )
                                 let totalTVA_2_1 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
                                  if (!isNaN(totalLigneHT) && tauxDeTVA == 2.1) {
                                    return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
                                  } else {
                                    return 0
                                  }
                                })
                            
                                let totalTVA_5_5 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
                                  if (!isNaN(totalLigneHT) && tauxDeTVA == 5.5) {
                                    return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
                                  } else {
                                    return 0
                                  }
                                })
                            
                                let totalTVA_10 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
                                  if (!isNaN(totalLigneHT) && tauxDeTVA == 10) {
                                    return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
                                  } else {
                                    return 0
                                  }
                                })
                            
                                let totalTVA_20 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
                                  if (!isNaN(totalLigneHT) && tauxDeTVA == 20) {
                                    return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
                                  } else {
                                    return 0
                                  }
                                })
                              
                                 // mise a jour des lignes visible en fonction de la valeur
                                 this.props.onUpdateTVA(totalTVA_2_1!= 0, totalTVA_5_5 != 0, totalTVA_10 != 0, totalTVA_20 != 0) 
                              }}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                )
              })}
              {this.props.avoir != true ? 
              <Grid.Row textAlign={'center'}>
                <Grid.Column>
                  <Button
                    type="button"
                    content="Ajouter"
                    size="mini"
                    disabled={this.props.view || this.props.avoir} // mode consultation
                    positive
                    onClick={() => fields.push({})}
                  />
                </Grid.Column>
              </Grid.Row>
               :''} 
            </Grid>
          )
        }}
      </FieldArray>
    )
  }
}
