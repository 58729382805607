import React, { Component } from 'react'
import { Form as FinalForm, Field, useForm } from 'react-final-form'
import { Form as SemanticForm } from 'semantic-ui-react'
import SubHeader from '../shared/subHeader/SubHeader'
import {
  composeValidators,
  minLength,
  isRequired,
  maxLength,
  mustBeNumber,
  minValue,
} from '../../utils/validators'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import tauxDeTVA from '../../api/enum/taux-tva'

class ProduitForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      varietes: [],
      comptesComptables: [],
    }

    ipcRenderer.invoke(Channels.VARIETE_FIND_ALL, { order: { libelle: 'ASC' } }).then(({ data }) => {
      this.setState({
        varietes: data,
      })
    })

    ipcRenderer.invoke(Channels.COMPTE_COMPTABLE_FIND_ALL, { order: { intitule: 'ASC' }}).then(({ data }) => {
      this.setState({
        comptesComptables: data,
      })
    })
  }

  componentDidUpdate(prevProps, prevState, snapShot) {}
  onSubmit = (formValues) => {
    console.log("SUBMIT:")
    console.log(formValues)
    /* const stock = parseFloat(formValues.stock.toString())
    formValues.stock = isNaN(stock) ? 0 : stock */
    const prix = parseFloat(formValues.prixHT.toString())
    formValues.prixHT = isNaN(prix) ? 0 : prix
    if (!formValues.tauxDeTVA) {
      formValues.tauxDeTVA = 0
    } else {
      formValues.tauxDeTVA = parseFloat(formValues.tauxDeTVA)
    }
    this.props.onSubmit(formValues)
  }

  render() {
    const tvaOptions = []
    for (const tva in tauxDeTVA) {
      if (!isNaN(Number(tva))) {
        tvaOptions.push({
          key: tauxDeTVA[tva],
          value: tva,
          text: `${tauxDeTVA[tva]} (${tva}%)`,
        })
      }
    }
    return (
      <FinalForm
        onSubmit={this.onSubmit}
        initialValues={this.props.initialValues}
        render={({ handleSubmit }) => {
          return (
            <SemanticForm onSubmit={handleSubmit}>
              <div className="ui content">
                <div className="ui segment">
                  <SubHeader
                    title={this.props.formTitle}
                    actions={this.props.headerActions}
                  />
                  <div className="ui divider" />
                  <SemanticForm.Group widths="equal">
                    <Field
                      name="code"
                      readOnly={this.props.view}
                      validate={composeValidators(
                        isRequired,
                        minLength(1),
                        maxLength(8)
                      )}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Code"
                          readOnly={this.props.view}
                          type="text"
                          placeholder="Code du produit"
                          autoComplete="off" 
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                    <Field
                      name="libelle"
                      validate={composeValidators(isRequired, minLength(3))}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Libellé"
                          type="text"
                          placeholder="Libellé du produit"
                          autoComplete="off" 
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>

                    {/* <Field
                      name="stock"
                      validate={composeValidators(isRequired, mustBeNumber)}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Stock"
                          type="text"
                          placeholder="Stock du produit"
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field> */}
                    <Field
                      name="prixHT"
                      validate={composeValidators(isRequired, mustBeNumber)}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Prix HT"
                          type="text"
                          placeholder="prix unitaire HT du produit"
                          autoComplete="off" 
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                    <Field name="tauxDeTVA" validate={isRequired}>
                      {({ input, meta }) => {
                        const form = useForm()
                        return (
                          <SemanticForm.Select
                            {...input}
                            fluid
                            label="Taux TVA"
                            //disabled={this.props.view}
                            onChange={async (value, data) => {
                              return input.onChange(data.value)
                            }}
                            onClose={async (event, data) => {
                              
                            }}
                            options={tvaOptions}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )
                      }}
                    </Field>
                    <Field
                      name="varieteId"
                      validate={composeValidators(isRequired)}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Field>
                          <SemanticForm.Dropdown
                            {...input}
                            placeholder="Sélectionnez une catégorie"
                            search
                            fluid
                            selection
                            onBlur={(e) => e.preventDefault()}
                            options={this.state.varietes.map((variete) => {
                              return {
                                key: variete.id,
                                value: variete.id,
                                text: `${variete.code} - ${variete.libelle}`,
                              }
                            })}
                            label="Catégorie"
                            onChange={(event, data) => {
                              return input.onChange(data.value)
                            }}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                      )}
                    </Field>
                    <Field 
                      name="compteComptableId" 
                      validate={composeValidators(isRequired)}
                    >
                        {({ input, meta }) => (
                          <SemanticForm.Field>
                            <SemanticForm.Dropdown
                            {...input}
                            placeholder="Sélectionnez un compte comptable"
                            search
                            fluid
                            selection
                            onBlur={(e) => e.preventDefault()}
                            options={this.state.comptesComptables.map((compteComptable) => {
                              return {
                                key: compteComptable.id,
                                value: compteComptable.id,
                                text: `${compteComptable.numero} - ${compteComptable.intitule}`,
                              }
                            })}
                            label="Compte comptable"
                            onChange={(event, data) => {
                              return input.onChange(data.value)
                            }}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                        )}
                    </Field>
                  </SemanticForm.Group>
                  <SemanticForm.Group>
                  <Field
                      name="description"
                    >
                      {({ input, meta }) => (
                        <SemanticForm.TextArea
                          {...input}
                          label="Description"
                          placeholder="Description du produit"
                          autoComplete="off" 
                          error={meta.error && meta.touched ? meta.error : null}
                          style={{ width: "800px"}}
                          onInput={(e) => {
                            e.target.style.height = "auto"; 
                            e.target.style.height = `${e.target.scrollHeight}px`; 
                          }}
                        />
                      )}
                    </Field>
                    </SemanticForm.Group>
                    <SemanticForm.Group>
                    <Field name="isActive" type="checkbox">
                      {({ input, meta }) => (
                        <SemanticForm.Checkbox
                          {...input}
                          toggle
                          label="Actif"
                          onChange={(event, { checked }) => {
                            return input.onChange(checked)
                          }}
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                  </SemanticForm.Group>
                </div>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default ProduitForm
