export enum Channels {
  // Avoir
  AVOIR_FIND_ALL = 'avoir:findAll',
  AVOIR_CREATE = 'avoir:create',
  AVOIR_UPDATE = 'avoir:update',
  AVOIR_FIND_BY_ID = 'avoir:findById',
  AVOIR_PRINT = 'avoir:print',
  AVOIR_DELETE = 'avoir:remove',
  
  // Bon_de_livraison
  BON_DE_LIVRAISON_FIND_ALL = 'bon_de_livraison:findAll',
  BON_DE_LIVRAISON_CREATE = 'bon_de_livraison:create',
  BON_DE_LIVRAISON_CREATE_FROM_DEVIS = 'bon_de_livraison:createFromDevis',
  BON_DE_LIVRAISON_UPDATE = 'bon_de_livraison:update',
  BON_DE_LIVRAISON_FIND_BY_ID = 'bon_de_livraison:findById',
  BON_DE_LIVRAISON_PRINT = 'bon_de_livraison:print',

  // Bon_de_livraison_ligne
  BON_DE_LIVRAISON_LIGNE_FIND_ALL = 'bon_de_livraison_ligne:findAll',
  BON_DE_LIVRAISON_LIGNE_CREATE = 'bon_de_livraison_ligne:create',
  BON_DE_LIVRAISON_LIGNE_UPDATE = 'bon_de_livraison_ligne:update',
  BON_DE_LIVRAISON_LIGNE_FIND_BY_ID = 'bon_de_livraison_ligne:findById',

  // Contact
  CONTACT_FIND_ALL = 'contact:findAll',
  CONTACT_CREATE = 'contact:create',
  CONTACT_UPDATE = 'contact:update',
  CONTACT_FIND_BY_ID = 'contact:findById',
  CONTACT_GERANT = 'contact:gerant',
  LOGO_UPLOAD = "contact:uploadLogo",

  // Contact_Info
  CONTACT_INFO_FIND_ALL = 'contact_info:findAll',
  CONTACT_INFO_CREATE = 'contact_info:create',
  CONTACT_INFO_UPDATE = 'contact_info:update',
  CONTACT_INFO_FIND_BY_ID = 'contact_info:findById',

  // Cotisation
  COTISATION_FIND_ALL = 'cotisation:findAll',
  COTISATION_CREATE = 'cotisation:create',
  COTISATION_UPDATE = 'cotisation:update',
  COTISATION_FIND_BY_ID = 'cotisation:findById',

  // Compte_Comptable
  COMPTE_COMPTABLE_FIND_ALL = 'compte_comptable:findAll',
  COMPTE_COMPTABLE_CREATE = 'compte_comptable:create',
  COMPTE_COMPTABLE_UPDATE = 'compte_comptable:update',
  COMPTE_COMPTABLE_FIND_BY_ID = 'compte_comptable:findById',
  
  // Devis
  DEVIS_FIND_ALL = 'devis:findAll',
  DEVIS_CREATE = 'devis:create',
  DEVIS_DUPLICATE = 'devis:duplicate',
  DEVIS_UPDATE = 'devis:update',
  DEVIS_FIND_BY_ID = 'devis:findById',
  DEVIS_PRINT = 'devis:print',

  // Devis_ligne
  DEVIS_LIGNE_FIND_ALL = 'devis:findAll',
  DEVIS_LIGNE_CREATE = 'devis:create',
  DEVIS_LIGNE_UPDATE = 'devis:update',
  DEVIS_LIGNE_FIND_BY_ID = 'devis:findById',

  // Emballage
  EMBALLAGE_FIND_ALL = 'emballage:findAll',
  EMBALLAGE_CREATE = 'emballage:create',
  EMBALLAGE_UPDATE = 'emballage:update',
  EMBALLAGE_FIND_BY_ID = 'emballage:findById',
  EMBALLAGE_STATS = 'emballage:stats',

  // Facture
  FACTURE_FIND_ALL = 'facture:findAll',
  FACTURE_CREATE = 'facture:create',
  FACTURE_UPDATE = 'facture:update',
  FACTURE_FIND_BY_ID = 'facture:findById',
  FACTURE_PRINT = 'facture:print',

  // Prodouane
  PRODOUANE_FIND_ALL = 'prodouane:findAll',
  PRODOUANE_CREATE = 'prodouane:create',
  PRODOUANE_UPDATE = 'prodouane:update',
  PRODOUANE_FIND_BY_ID = 'prodouane:findById',
  PRODOUANE_PRINT = 'prodouane:print',
  PRODOUANE_EXPORT = 'prodouane:export',

  // Facture_ligne
  FACTURE_LIGNE_FIND_ALL = 'facture_ligne:findAll',
  FACTURE_LIGNE_CREATE = 'facture_ligne:create',
  FACTURE_LIGNE_UPDATE = 'facture_ligne:update',
  FACTURE_LIGNE_FIND_BY_ID = 'facture_ligne:findById',

  // Facture_transporteur
  FACTURE_TRANSPORTEUR_FIND_ALL = 'facture_transporteur:findAll',
  FACTURE_TRANSPORTEUR_CREATE = 'facture_transporteur:create',
  FACTURE_TRANSPORTEUR_UPDATE = 'facture_transporteur:update',
  FACTURE_TRANSPORTEUR_FIND_BY_ID = 'facture_transporteur:findById',

  // Famille
  FAMILLE_FIND_ALL = 'famille:findAll',
  FAMILLE_CREATE = 'famille:create',
  FAMILLE_UPDATE = 'famille:update',
  FAMILLE_FIND_BY_ID = 'famille:findById',

  // Mention
  MENTION_FIND_ALL = 'mention:findAll',
  MENTION_CREATE = 'mention:create',
  MENTION_UPDATE = 'mention:update',
  MENTION_FIND_BY_ID = 'mention:findById',

  // Parcelle
  PARCELLE_FIND_ALL = 'parcelle:findAll',
  PARCELLE_CREATE = 'parcelle:create',
  PARCELLE_UPDATE = 'parcelle:update',
  PARCELLE_FIND_BY_ID = 'parcelle:findById',

  // Pays
  PAYS_FIND_ALL = 'pays:findAll',
  PAYS_CREATE = 'pays:create',
  PAYS_UPDATE = 'pays:update',
  PAYS_FIND_BY_ID = 'pays:findById',

  // Produit
  PRODUIT_FIND_ALL = 'produit:findAll',
  PRODUIT_CREATE = 'produit:create',
  PRODUIT_UPDATE = 'produit:update',
  PRODUIT_FIND_BY_ID = 'produit:findById',

  // Societe
  SOCIETE_FIND_ALL = 'societe:findAll',
  SOCIETE_CREATE = 'societe:create',
  SOCIETE_UPDATE = 'societe:update',
  SOCIETE_FIND_BY_ID = 'societe:findById',

  // Traite
  TRAITE_FIND_ALL = 'traite:findAll',
  TRAITE_CREATE = 'traite:create',
  TRAITE_UPDATE = 'traite:update',
  TRAITE_FIND_BY_ID = 'traite:findById',

  // Variete
  VARIETE_FIND_ALL = 'variete:findAll',
  VARIETE_CREATE = 'variete:create',
  VARIETE_UPDATE = 'variete:update',
  VARIETE_FIND_BY_ID = 'variete:findById',

  // Stats
  STATS_ACHATS_VENTES = 'stats:achat_vente',
  STATS_CLIENT = 'stats:client',
  STATS_FOURNISSEUR = 'stats:fournisseur',
  STATS_PARCELLE = 'stats:parcelle',
  STATS_VENTE_JOURNALIERE = 'stats:vente_journaliere',
  
  // SOLDE
  SOLDE = 'solde',
  EMAIL_SEND = 'email:send',

}
