import React, { Component, Fragment } from 'react'
import { DocType } from '../../utils/enum-divers'
import { Container, Divider } from 'semantic-ui-react'
import SubHeader from '../shared/subHeader/SubHeader'
import { connect } from 'react-redux'
import { fetchDesDevis } from '../../redux/actions/devis-action'
import DataTables from '../shared/datatable/Datatable'
import Pagination from '../shared/datatable/Pagination'
import { history } from '../../utils/history'
import { cellFormat } from '../shared/datatable/cell-format'
import DevisSearchForm from './DevisSearchForm'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import ExportPdf from '../shared/exportcsv/export-pdf'
import moment from 'moment'
const _ = require('lodash');

class Devis extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cols: [
        {
          text: 'Numéro',
          key: 'numero',
        },
        {
          text: 'Date',
          key: 'date',
          format: cellFormat.DATE,
        },
        {
          text: 'N° Facture',
          key: 'facture.numero',
        },
        {
          text: 'Date facture',
          key: 'facture.date',
          format: cellFormat.DATE,
        },
        {
          text: 'Signé',
          key: 'isSigned',
        },
        {
          text: 'Perdu',
          key: 'isPerdu',
        },
        {
          text: 'Code client',
          key: 'recepteur.code',
        },
        {
          text: 'Client',
          key: 'recepteur.nom',
        },
        {
          text: 'Montant HT',
          key: 'totalPrixHT',
          format: cellFormat.MONEY,
        },
      ],
      type: this.props.match.params.type,
    }
  }

  componentDidMount() {
    const { queryDVAchat, queryDVVente } = this.props.listeDevis

    const query =
      this.props.match.params.type === 'ACHAT' ? queryDVAchat : queryDVVente

    this.props.fetchDesDevis(query)
  }

  // Pour le passage de DV vente à DV achat et vice versa
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Si le type a changé
    if (this.props.match.params.type != prevProps.match.params.type) {
      const { queryDVAchat, queryDVVente } = this.props.listeDevis

      // Récupération de la query
      const query =
        this.props.match.params.type === 'ACHAT' ? queryDVAchat : queryDVVente

      this.props.fetchDesDevis(query)
    }
  }
  async generateFileName() {
    let filename = 'Export_DV'
    // Build filenamewith query
    const { queryDVAchat, queryDVVente } = this.props.listeDevis
    const { params } =
      this.props.match.params.type === 'ACHAT' ? queryDVAchat : queryDVVente
    filename += `${params.type ? '_' + params.type : ''}`
    filename += `${params.numero ? '_' + params.numero : ''}`
    filename += `${params.numeroFacture ? '_' + params.numeroFacture : ''}`
    filename += `${
      params.fromDate ? '_' + moment(params.fromDate).format('DD/MM/YYYY') : ''
    }`
    filename += `${
      params.toDate ? '_' + moment(params.toDate).format('DD/MM/YYYY') : ''
    }`
    if (params.recepteurId) {
      const recepteur = await ipcRenderer.invoke(
        Channels.CONTACT_FIND_BY_ID,
        params.recepteurId
      )
      filename += `${recepteur.code ? '_' + recepteur.code : ''}`
    }
    filename += `${params.priceless ? '_Sans_Prix' : ''}`
    filename += `${params.isFactureDone ? '_Avec_Facture' : ''}`
    return filename
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <ExportPdf
          filenameGeneratorFunc={async () => await this.generateFileName()}
          csvData={this.getDataForExport}
          headers={this.state.cols}
        />
        <button
          className="ui button teal"
          onClick={() => {
            history.push(
              `/devis/${this.props.match.params.type}/create`
            )
          }}
          type="button"
        >
          <i className="icon plus" />
          Ajouter
        </button>
      </Fragment>
    )
  }

  getActionRow = (row) => {
    const { type } = this.props.match.params
    if (!row.id) {
      return null
    }
    const  isEdit  = !row.isPrinted
    return (
      <Fragment>
        <button
          className="ui button blue"
          type="button"
          onClick={() => {
            history.push(`/devis/view/${row.id}`)
          }}
        >
          <i className="icon eye" />
          Consulter
        </button>

        {isEdit==true?(
        <button
          className="ui button olive"
          type="button"
          onClick={() => {
            history.push(`/devis/${type}/edit/${row.id}`)
          }}
        >
          <i className="icon pencil" />
          Editer
        </button>
        ):''}
      </Fragment>
    )
  }

  onPageClick = (pageInfo) => {
    const { queryDVAchat, queryDVVente } = this.props.listeDevis

    let query =
      this.props.match.params.type === 'ACHAT' ? queryDVAchat : queryDVVente
    query.skip = (pageInfo.currentPage - 1) * query.take
    this.props.fetchDesDevis(query)
  }

  onSubmitSearch = (formValues) => {
    // Si priceless est à false, on ne prend pas en compte le critère
    /* if (!formValues?.priceless) */ delete formValues.priceless

    const { queryDVAchat, queryDVVente } = this.props.listeDevis

    const query =
      this.props.match.params.type === 'ACHAT' ? queryDVAchat : queryDVVente
    query.params = formValues
    if (query.params.isSigned == false)
      query.params.isSigned = 0
    query.skip = 0
    query.take = 10
    console.log(`Query devis: ${JSON.stringify(query)}`)
    this.props.fetchDesDevis(query)
  }

  onResetSearch = () => {
    const { queryDVAchat, queryDVVente } = this.props.listeDevis

    const query =
      this.props.match.params.type === 'ACHAT' ? queryDVAchat : queryDVVente

    query.params = {
      type: this.props.match.params.type,
      isFactureDone: false,
      fromDate: new Date(new Date().getFullYear(), 0, 1),
      toDate: new Date(new Date().getFullYear(), 11, 31),
    }

    this.props.fetchDesDevis(query)
  }

  onColumnClick = (col) => {
    const { queryDVAchat, queryDVVente } = this.props.listeDevis

    let query =
      this.props.match.params.type === 'ACHAT' ? queryDVAchat : queryDVVente

    // Si on tri déjà par ce champs
    if (query.order[col]) {
      // Si le tri est déjà en ASC, on switch en DESC
      if (query.order[col] === 'ASC') {
        query.order[col] = 'DESC'
        // Sinon ASC
      } else {
        query.order[col] = 'ASC'
      }
    } else {
      query.order = { [col]: 'ASC' }
    }
    this.props.fetchDesDevis(query)
  }

  getDataForExport = async () => {
    const { type } = this.props.match.params
    const { queryDVAchat, queryDVVente } = this.props.listeDevis

    const { data } = await ipcRenderer.invoke(
      Channels.DEVIS_FIND_ALL,
      _.omit(type === 'ACHAT' ? queryDVAchat : queryDVVente, ['skip', 'take'])
    )

    return _.map(data, (bl) => ({
      numero: bl.numero,
      type: bl.type,
      date: moment(bl.date).format('DD/MM/YYYY'),
      'recepteur.code': bl.recepteur?.code,
      'recepteur.nom': bl.recepteur?.nom,
      isFactureDone: bl?.isFactureDone == 0 ? false : true,
      'facture.numero': bl?.facture?.numero,
      'facture.date': bl?.facture
        ? moment(bl?.facture?.date).format('DD/MM/YYYY')
        : '',
      totalPrixHT: bl.totalPrixHT,
    }))
  }

  render() {
    const { type } = this.props.match.params
    const { queryDVAchat, queryDVVente } = this.props.listeDevis

    let query =
      this.props.match.params.type === 'ACHAT' ? queryDVAchat : queryDVVente

    return (
      <Container fluid>
        <div className="ui segment">
          <SubHeader
              /*  title={`Liste des devis - ${type === DocType.ACHAT ? 'Achats' : 'Ventes'} `} */
              title={`Liste des devis`}
              actions={this.getHeaderActions()}
          />
          <Divider />

          <DevisSearchForm
            onSubmit={this.onSubmitSearch}
            initialValues={query.params}
            onResetSearch={this.onResetSearch}
            type={type}
          />
          <DataTables
            columns={this.state.cols}
            rows={this.props.listeDevis.data}
            onColumnClick={this.onColumnClick}
            actionRow={this.getActionRow}
            query={query}
          />
          <Pagination
            onPageClick={this.onPageClick}
            itemPerPage={query.take}
            pageNeighbours={1}
            totalItems={this.props.listeDevis.count}
          />
          <Divider />
        <SubHeader
            title={''}
            actions={this.getHeaderActions()}
            repetitionEnbas={this.props.listeDevis.count > 10 ? true : false}
          />
        </div>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  let dataInitial = state.listeDevis.ids
  ? state.listeDevis.ids.map((id) => {
      return state.listeDevis.byIds[id]
    })
  : []

  return {
    listeDevis: {
      count: state.listeDevis.count,
      data: state.listeDevis.ids
        ? state.listeDevis.ids.map((id) => {
            let DV = state.listeDevis.byIds[id]
            DV.date = moment(DV.date).format('DD/MM/YYYY')
            if(DV.facture)
              DV.facture.date = DV.facture?moment(DV.facture.date).format('DD/MM/YYYY'):''
            return DV
          })
        : [],
      queryDVAchat: state.listeDevis.queryDVAchat,
      queryDVVente: state.listeDevis.queryDVVente,
    },
  }
}

export default connect(mapStateToProps, { fetchDesDevis })(
  Devis
)
