import React, { useState, useEffect } from 'react'
import {Icon} from 'semantic-ui-react';
import './UserPwd.css'

const UserPwd = (props) => {
const [email, setEmail] = useState('')
const [password1, setPassword1] = useState('')
const [password2, setPassword2] = useState('')
const [passwordError, setPasswordError] = useState('')
const [resetToken, setResetToken] = useState('')
const [passwordOk, setPasswordOk] = useState('')

const [type, setType] = useState('password');
const [icon, setIcon] = useState("eye slash");

useEffect(() => {
  // Ask API for reset password token
  askResetPassword((token) => {
    // If Reset token ok
    setPasswordError('')
    setPasswordOk('')
    if (token) 
      setResetToken(token)
    // Else, error message
    else  {
        setPasswordError('Erreur interne veuillez réessayer ulterieurement !')
        setPasswordOk('')
    }
  })

}, [])

const handleToggle = () => {
    if (type==='password'){
      setIcon("eye");
      setType('text');
    } else {
      setIcon("eye slash");   
      setType('password');
    }
 }

  const onButtonModifClick = () => {

    // Set initial error values to empty
    setPasswordError('')
    setPasswordOk('')

     // check if 2 fois la meme saisie
    if (password1 != password2) {
      setPasswordError('Les 2 mots de passes saisis ne sont pas identiques')
      return
    } 
      
    if ('' === password1) {
        setPasswordError('Veuillez entrer un mot de passe')
        return
    }
    
    if (password1.length < 7) {
        setPasswordError('Le mot de passe doit avoir 8 caractères minimum')
        return
    }
    
    // Check if email has an account associated with it
    checkAccountExists((accountExists) => {
      // If yes, log in
      if (accountExists) resetPassword()
      // Else, error message
      else  {
          setPasswordError('Utilisateur ou mot de passe invalide')
      }
    })
  }

  // Call the server API to check if the given email ID already exists
const checkAccountExists = (callback) => {
  const API  = (process.env.NODE_ENV === 'development') ? (process.env.REACT_APP_API_DEV || 'http://localhost:3001/') : (process.env.REACT_APP_API_PROD || 'http://localhost:3001/')

    fetch(API + 'check-account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((r) => r.json())
      .then((r) => {
        callback(r?.userExists)
      })
  }
// Call the server API to check if the given email ID already exists
const askResetPassword = (callback) => {
  // Fetch the user email and token from local storage
  const user = JSON.parse(localStorage.getItem('user'))
  setEmail(user.email)
  const API  = (process.env.NODE_ENV === 'development') ? (process.env.REACT_APP_API_DEV || 'http://localhost:3001/') : (process.env.REACT_APP_API_PROD || 'http://localhost:3001/')

  fetch(API + 'ask-reset-password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({email: user.email}),
  })
    .then((r) => r.json())
    .then((r) => {
      callback(r?.token)
    })
}
  
  //reset password
  const resetPassword = () => {
    const API  = (process.env.NODE_ENV === 'development') ? (process.env.REACT_APP_API_DEV || 'http://localhost:3001/') : (process.env.REACT_APP_API_PROD || 'http://localhost:3001/')

    fetch(API + 'reset-password/'+resetToken, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify( {password: password1} ),
    })
      .then((r) => r.json())
      .then((r) => {
        if ('Mot de passe réinitialisé avec succès' === r.message) {
          setPasswordOk(r.message) // RAF gerer dans un autre label
          setPasswordError('') 
        } else {
            setPasswordError('Mauvais email ou mot de passe') 
            setPasswordOk('')
        }
      })
  }

  return (
    <div className={'UserPwdMainContainer'}>
      <div className={'labelemail'}>
        <div>{email}</div>
      </div>
      <br />
      <div className="inputContainer">
        <div className="inputWrapper">
            <input
            type={type}
            name="password1"
            value={password1}
            placeholder="Entrez votre nouveau mot de passe"
            autoComplete="off" 
            onChange={(ev) => setPassword1(ev.target.value)}
            className="UserPwdInputBox"
            /> 
            <span className="eyeIcon" onClick={handleToggle}>
                <Icon name={icon} />
            </span>
          </div>
      </div>
      <br />
      <div className="inputContainer">
        <div className="inputWrapper">
            <input
            type={type}
            name="password2"
            value={password2}
            placeholder="Confirmez le mot de passe"
            autoComplete="off" 
            onChange={(ev) => setPassword2(ev.target.value)}
            className="UserPwdInputBox"
            /> 
            <span className="eyeIcon" onClick={handleToggle}>
                <Icon name={icon} />
            </span>
          </div>
        <label className="errorLabel">{passwordError}</label>
      </div>
      <br />
      <div className="inputContainer">
        <input className="UserPwdInputButton" type="button" onClick={onButtonModifClick} value={'Modifier le mot de passe'} />
      </div>
      <div className="inputContainer">
        <label className="okLabel">{passwordOk}</label>
      </div>
    </div>
  )
}

export default UserPwd