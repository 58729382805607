import React, { Fragment } from 'react'
import DevisForm from './DevisForm'
import { DocType } from '../../utils/enum-divers'
import { Button, Container, Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { history } from '../../utils/history'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { fetchUnDevis } from '../../redux/actions/devis-action'
import { Field } from 'react-final-form'
import CustomModal from '../shared/modal/CustomModal'
import EmailModal from '../shared/modal/EmailModal'
import { toast } from 'react-toastify'
import { handleErrorResponse } from '../../utils/handle-error-response'
const _ = require('lodash');

class DevisView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitBtn: null,
      showModalPrint: false,
      showModalMail: false,
      defaultEmail: '',
    }
  }

  modalActionsMail() {
    return (
      <Fragment>
        <button className="ui button teal left labeled icon" type="submit">
          <i className="mail icon"></i>
          Envoyer
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalMail: false,
            })
          }}
        >
          Fermer
        </button>
      </Fragment>
    )
  }

  emailSubmit = (email, objet, message) => {
    ipcRenderer
      .invoke(Channels.EMAIL_SEND, {
        email,
        objet,
        message,
        typeDocument: 'Dv',
        idDocument: this.props.devis.id,
        numero: this.props.devis.numero,
      })
      .then((response) => {
        if (response.error) {
          handleErrorResponse(response.error)
        } else {
          history.push(`/devis/${this.props.devis.type}`)
          toast.success(
            `Le devis ${this.props.devis.numero} a bien été créé et l'email a bien été envoyé`
          )
        }
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  getRecepteurEmail = (id) => {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_BY_ID, id)
      .then((response) => {
        this.setState({
          defaultEmail: response.email,
        })
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button
          icon="print"
          type="button"
          content="Imprimer"
          color="teal"
          onClick={() => {
            this.setState({
              showModalPrint: true,
            })
          }}
        />
        <Button
          icon="mail"
          type="button"
          content="Email"
          color="blue"
          onClick={() =>
            this.setState({
              showModalMail: true,
            })
          }
        />
        <Button
          icon="file alternate outline"
          type="submit"
          content="Dupliquer"
          color="yellow"
          style={{ display: (!this.props.devis || !this.props.devis.isPrinted) ? "none" : "inline-block" }}
          onClick={() => {
            this.setState({ ...this.state, submitBtn: 'dupliquer' })
          }}
        />
        <Button
          icon="reply"
          type="button"
          content="Retour à la liste"
          color="black"
          onClick={() => {
            history.push(`/devis/${this.props.devis.type}`)
          }}
        />
      </Fragment>
    )
  }

  modalActionsCancel() {
    return (
      <Fragment>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalPrint: false,
            });
            const { id } = this.props.match.params;
            ipcRenderer
            .invoke(Channels.DEVIS_PRINT, {
              data: id,
              options: { printParcelles: true },
            })
            .then((response) => {
              const pdfPath = response.filePath;
              if (pdfPath) {
                window.open(pdfPath);
                toast.success(`Le devis a bien été créé`);
              } else {
                toast.error("Le chemin du PDF est manquant ou invalide.");
              }
            })
            .catch((error) => {
              console.error(error);
              toast.error('Erreur lors de la création du devis.');
            });
          }}
        >
          <i className="checkmark icon" />
          Impression PDF
        </button>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalPrint: false,
            });
            console.log("Action annulée");
          }}
        >
          Non
        </button>
      </Fragment>
    );
  }
  
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.fetchUnDevis(id)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.devis !== prevProps.devis) {
      this.getRecepteurEmail(this.props.devis?.recepteurId)
    }
  }

  onFormSubmit = async (formValues) => {
    try {
      const { type } = this.props.match.params;
      
      switch (this.state.submitBtn) {
        case 'dupliquer':
          const response = await ipcRenderer.invoke(Channels.DEVIS_DUPLICATE, {
            id: this.props.devis.id,
          });
      
          console.log(`Reponse: ${response}`)
          if (response.hasError) {
            handleErrorResponse(response.errors);
            return;
          }

          history.push(`/devis/${type}/edit/${response.id}`);
          toast.success(`Le devis ${response.numero} a bien été dupliqué`);
          break;              
        default:
          history.push(`/devis/${type}`);
          break;
      }     
    } catch (error) {
      console.log(error);
      handleErrorResponse(error);
    }
  };

  render() {
    const { devis } = this.props
    const initialValues = devis

    return initialValues ? (
      <Fragment>
        <DevisForm
          /* formTitle={`Consulter un devis - ${initialValues.type === DocType.ACHAT ? 'Achats' : 'Ventes'} `} */
          formTitle={`Consulter un devis`}
          type={
            initialValues.type === DocType.ACHAT ? DocType.ACHAT : DocType.VENTE
          }
          view={true}
          headerActions={this.getHeaderActions()}
          initialValues={initialValues}
          onSubmit={this.onFormSubmit}
        ></DevisForm>
        <EmailModal
          title="Envoi du devis par mail"
          visible={this.state.showModalMail}
          actions={this.modalActionsMail()}
          id={initialValues.id}
          numero={initialValues.numero}
          email={this.state.defaultEmail}
          typeDocument="Dv"
          onSubmit={this.emailSubmit}
        />
        <CustomModal
          title="Imprimer"
          content="Imprimer le devis"
          visible={this.state.showModalPrint}
          actions={this.modalActionsCancel()}
        />
      </Fragment>
    ) : (
      <div>Chargement</div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    devis: state.listeDevis.devis,
    theme: state.theme,
  }
}

export default connect(mapStateToProps, { fetchUnDevis })(
  DevisView
)
