import React from 'react'
import {
  Button,
  Container,
  Grid,
  Message,
  Segment,
  TextArea,
} from 'semantic-ui-react'
import { Form as FinalForm, Field, useForm } from 'react-final-form'
import { Form as SemanticForm } from 'semantic-ui-react'
import SubHeader from '../shared/subHeader/SubHeader'
import SelectContact from './partials/header/SelectContact'
import DatePicker from 'react-datepicker'
import BonDeLivraisonFormTable from './partials/lignes/BonDeLivraisonFormTable'
import arrayMutators from 'final-form-arrays'
import { composeValidators, isRequired } from '../../utils/validators'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import moment from 'moment/moment'
import { updateMontantRemiseIndicative } from './partials/common-form-update'
import { toast } from 'react-toastify'
const _ = require('lodash');

class BonDeLivraisonForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      view: false,
      dateValid: new Map(),
      facturesTransporteur: [],
      mentions: [],
      defaultMention: 'RESERVE_BL',
      isTTC: false
    }
    
  }

  componentDidMount() {
    ipcRenderer
      .invoke(Channels.FACTURE_TRANSPORTEUR_FIND_ALL, {
        order: { numero: 'ASC' },
      })
      .then(({ data }) => {
        this.setState({
          facturesTransporteur: data,
        })
      })
    this.setState({ view: this.props.view })
    this.getMentionsInState()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      this.setState({ view: this.props.view })
    }
  }

  getMentionsInState = () => {
    ipcRenderer.invoke(Channels.MENTION_FIND_ALL, {}).then(({ data }) => {
      this.setState({
        mentions: data,
      })
    })
  }

  renderViewFields() {
    const { initialValues } = this.props
    const view = this.props.view
    if (view) {
      return (
        <Grid.Column width={16}>
          <Field name="isFactureDone" type="checkbox">
            {({ input, meta }) => (
              <SemanticForm.Checkbox
                {...input}
                toggle
                readOnly
                label="Facturé"
                onChange={(event, { checked }) => {
                  return input.onChange(checked)
                }}
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
          <label>Vous ne pouvez pas modifier ce bon de livraison.</label>
          {initialValues.isFactureDone ? (
            <label>
              {' '}
              Il est lié à la facture : {initialValues.facture?.numero}
            </label>
          ) : (
            ''
          )}
        </Grid.Column>
      )
    }
  }
  onFormSubmit = (formValues) => {
    //console.log('BLLLLLL submit form')
    if (formValues.date) {
      if (typeof formValues.date === 'number') {
          formValues.date = moment(formValues.date, 'DD/MM/YYYY')
        .hour(0)
        .minutes(0)
        .second(0)
        .toDate() 
      }
     
    } else {
      toast.error('Veuillez renseigner une date correcte')
    }

    // Parse pour pouvoir envoyer les données du formulaire en double
    formValues.totalPrixHT = formValues.totalPrixHT
      ? parseFloat(formValues.totalPrixHT)
      : 0
    formValues.totalTVA = formValues.totalTVA
      ? parseFloat(parseFloat(formValues.totalTVA))
      : 0
   
    formValues.totalTVA_2_1 = formValues.totalTVA_2_1
    ? parseFloat(parseFloat(formValues.totalTVA_2_1))
    : 0

    formValues.totalTVA_5_5 = formValues.totalTVA_5_5
    ? parseFloat(parseFloat(formValues.totalTVA_5_5))
    : 0

    formValues.totalTVA_10 = formValues.totalTVA_10
    ? parseFloat(parseFloat(formValues.totalTVA_10))
    : 0

    formValues.totalTVA_20 = formValues.totalTVA_20
    ? parseFloat(parseFloat(formValues.totalTVA_20))
    : 0

    formValues.totalTTC = formValues.totalTTC
      ? parseFloat(formValues.totalTTC)
      : 0

    formValues.totalMontantRemiseIndicative = formValues.totalMontantRemiseIndicative
      ? parseFloat(formValues.totalMontantRemiseIndicative)
      : 0
/*     formValues.remiseIndicative = formValues.remiseIndicative
      ? parseFloat(formValues.remiseIndicative)
      : 0 */
    if (!formValues.commentaire) {
      formValues.commentaire = null
    }
    if (!formValues.mentions) {
      formValues.mentions = null
    }
/*     formValues.remiseIndicative = formValues.remiseIndicative
      ? parseFloat(parseFloat(formValues.remiseIndicative).toFixed(2))
      : 0 */
    //if (formValues.bonDeLivraisonLignes) {
    //  for (let i = 0; i < formValues.bonDeLivraisonLignes.length; i++) {
    //    if (!formValues.bonDeLivraisonLignes[i].parcelleId) {
    //      formValues.bonDeLivraisonLignes[i].parcelleId = null
    //    }
    //  }
    //}
    this.props.onSubmit(formValues)
  }

  checkIfDateIsWithinCurrentYear = (date, field, message) => {
    // Zone
    const dateValid = this.state.dateValid

    // Si saisie d'une date hors année courante
    if (date.getFullYear() != new Date().getFullYear())
      dateValid.set(field, message)
    else dateValid.delete(field)

    this.setState({
      dateValid: dateValid,
    })
  }

  render() {
    const { type, initialValues, view } = this.props
    return (
      <Container fluid>
        <FinalForm
          onSubmit={this.onFormSubmit}
          initialValues={initialValues}
          keepDirtyOnReinitialize={true}
          mutators={{
            ...arrayMutators,
          }}
          render={({ handleSubmit, form, values }) => {
            return (
              <SemanticForm onSubmit={handleSubmit} size="massive">
                <Segment>
                  <SubHeader
                    title={this.props.formTitle}
                    actions={this.props.headerActions}
                  />
                  <div className="ui divider" />

                  <Container fluid>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <Message
                            hidden={this.state.dateValid.size <= 0}
                            icon="warning sign"
                            header="Certains éléments peuvent être incorrects"
                            list={[...this.state.dateValid.values()]}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid>
                      {this.renderViewFields()}
                      <Grid.Column floated="left" width={7}>
                        <SelectContact
                          type={type}
                          label={'Emetteur'}
                          fieldId={'emetteurId'}
                          fieldString={'emetteurAsString'}
                          mainForm={form}
                          view={this.props.view}
                        />
                      </Grid.Column>

                      <Grid.Column floated="left" width={7}>
                        <SelectContact
                          type={type}
                          label={'client'}
                          fieldId={'recepteurId'}
                          fieldString={'recepteurAsString'}
                          mainForm={form}
                          view={this.props.view}
                        />
                      </Grid.Column>
                    </Grid>
                    <Grid>
                      <Grid.Column width={4}>
                        <Field name={`type`}>
                          {({ input }) => <input {...input} type="hidden" />}
                        </Field>
                        <Field name="numero">
                          {({ input, meta }) => (
                            <SemanticForm.Input
                              {...input}
                              label="Numéro"
                              type="text"
                              placeholder="Généré automatiquement"
                              readOnly={true}
                              error={
                                meta.error && meta.touched ? meta.error : null
                              }
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Field
                          name="date"
                          validate={composeValidators(isRequired)}
                        >
                          {({ input, meta }) => {
                            return (
                              <SemanticForm.Field>
                                <label>Date</label>
                                <DatePicker
                                  {...input.value}
                                  locale="fr"
                                  showYearDropdown
                                  selected={
                                    (input.value && new Date(input.value)) ||
                                    null
                                  }
                                  onSelect={(date) => {
                                    this.checkIfDateIsWithinCurrentYear(
                                      date,
                                      input.name,
                                      'Date du bon de livraison'
                                    )
                                    input.onChange(date)
                                  }}
                                  onChange={(date) => {
                                    this.checkIfDateIsWithinCurrentYear(
                                      date,
                                      input.name,
                                      'Date du bon de livraison'
                                    )
                                    input.onChange(date)
                                  }}
                                  dateFormat="dd/MM/yyyy"
                                  readOnly={this.props.view}
                                />
                              </SemanticForm.Field>
                            )
                          }}
                        </Field>
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Field name={`type`}>
                          {({ input }) => <input {...input} type="hidden" />}
                        </Field>
                        {/* <Field name="remiseIndicative">
                          {({ input, meta }) => {
                            return (
                              <SemanticForm.Input
                                {...input}
                                label="% Remise à appliquer"
                                type="number"
                                // readOnly={false}
                                onChange={(event, data) => {
                                  input.onChange(data.value)
                                  updateMontantRemiseIndicative(form)
                                }}
                                readOnly={this.props.view}
                                error={
                                  meta.error && meta.touched ? meta.error : null
                                }
                              />
                            )
                          }}
                        </Field> */}
                      </Grid.Column>
                                <Grid.Column width={4}>
                        {/*<Field name="factureTransporteurId">*/}
                        {/*    {({ input, meta }) => (*/}
                        {/*        <SemanticForm.Field>*/}
                        {/*            <SemanticForm.Dropdown*/}
                        {/*                {...input}*/}
                        {/*                placeholder="Sélectionnez"*/}
                        {/*                fluid*/}
                        {/*                selection*/}
                        {/*                onBlur={(e) => e.preventDefault()}*/}
                        {/*                disabled={this.props.view}*/}

                        {/*                options={this.state.facturesTransporteur.map(*/}
                        {/*                    (fa) => {*/}
                        {/*                        return {*/}
                        {/*                            key: fa.id,*/}
                        {/*                            value: fa.id,*/}
                        {/*                            text: fa.numero,*/}
                        {/*                        }*/}
                        {/*                    }*/}
                        {/*                )}*/}
                        {/*                label="Facture transporteur"*/}
                        {/*                onChange={(event, data) => {*/}
                        {/*                    return input.onChange(data.value)*/}
                        {/*                }}*/}
                        {/*                error={*/}
                        {/*                    meta.error && meta.touched ? meta.error : null*/}
                        {/*                }*/}
                        {/*            />*/}
                        {/*        </SemanticForm.Field>*/}
                        {/*    )}*/}
                        {/*</Field>*/}
                      </Grid.Column>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <Field name="mentions">
                            {({ input, meta }) => (
                              <SemanticForm.Field>
                                <label>Mentions</label>
                                <TextArea
                                  {...input}
                                  readOnly={this.props.view}
                                  rows={8}
                                />
                              </SemanticForm.Field>
                            )}
                          </Field>
                        </Grid.Column>

                        <div
                          className={
                            this.props.view ? 'd-none' : 'six wide column'
                          }
                        >
                          <Grid.Column width={6}>
                            <Field name="mention">
                              {({ input, meta }) => (
                                <SemanticForm.Select
                                  {...input}
                                  fluid
                                  label="Insérer une mention"
                                  placeholder="Sélectionner une mention et cliquez sur Ajouter"
                                  onChange={(value, data) => {
                                    return input.onChange(data.value)
                                  }}
                                  selection
                                  options={this.state?.mentions?.map(
                                    (mention) => {
                                      return {
                                        key: mention.id,
                                        value: mention.mention,
                                        text: `${
                                          mention.descriptionCourte
                                        } - ${mention.mention?.substring(
                                          0,
                                          40
                                        )}${
                                          mention.mention?.length > 40
                                            ? '..'
                                            : ''
                                        }`,
                                      }
                                    }
                                  )}
                                  error={
                                    meta.error && meta.touched
                                      ? meta.error
                                      : null
                                  }
                                />
                              )}
                            </Field>
                            <Field name="input">
                              {({ input, meta }) => {
                                return (
                                  <Button
                                    {...input}
                                    icon="add"
                                    type="button"
                                    size="mini"
                                    content="Ajouter"
                                    color="teal"
                                    onClick={() => {
                                      const { mention, mentions } =
                                        form.getState().values
                                      let mentionsUpdate = ''
                                      if (mention) {
                                        if (mentions) {
                                          mentionsUpdate = `${mentions} \n${mention}`
                                        } else {
                                          mentionsUpdate = mention
                                        }
                                        form.change(`mentions`, mentionsUpdate)
                                      }
                                    }}
                                  />
                                )
                              }}
                            </Field>
                          </Grid.Column>
                        </div>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <Field name="commentaire">
                            {({ input, meta }) => (
                              <SemanticForm.Field>
                                <label>Commentaire</label>
                                <TextArea
                                  {...input}
                                  readOnly={this.props.view}
                                />
                              </SemanticForm.Field>
                            )}
                          </Field>
                          <Field name="TTC" type="checkbox">
                          {({ input, meta }) => (
                            <SemanticForm.Checkbox
                              {...input}
                              toggle
                              label="Saisie TTC"
                              onChange={(event, { checked }) => {
                                this.setState({ isTTC: checked }, () => {   // MAJ de l'état (true si TTC , false si HT)                                  
                                  const formState = form.getState(); //récupération de l'état actuel du formulaire
                                  const bons = formState.values.bonDeLivraisonLignes || []; //récupération des lignes du BL
                     
                                  bons.forEach((ligne, index) => {
                                    if (checked) {
                                      // Convertir le HT en TTC
                                      const prixTTC = ligne.totalLigneTTC / ligne.poidsBrut;
                                      form.change(`bonDeLivraisonLignes[${index}].prixUnitaireTTC`, prixTTC.toFixed(2)); //MAJ de la valeur
                                    }
                                  });
                                });
                      
                                return input.onChange(checked);

                              }}
                              error={meta.error && meta.touched ? meta.error : null}
                            />
                          )}
                        </Field>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Container>
                <BonDeLivraisonFormTable 
                view={this.props.view} 
                isTTC={this.state.isTTC}
                TVA_2_1_visible= {initialValues.totalTVA_2_1 && initialValues.totalTVA_2_1!=0}
                TVA_5_5_visible= {initialValues.totalTVA_5_5 && initialValues.totalTVA_5_5!=0}
                TVA_10_visible= {initialValues.totalTVA_10 && initialValues.totalTVA_10!=0}
                TVA_20_visible= {initialValues.totalTVA_20 && initialValues.totalTVA_20!=0}/>
                <div className="ui divider" />
                <SubHeader
                    title={''}
                    actions={this.props.headerActions}
                 />
                </Segment>
              </SemanticForm>
            )
          }}
        />
      </Container>
    )
  }
}

export default BonDeLivraisonForm
