import React, { Component, Fragment } from 'react'
import { Field, useForm } from 'react-final-form'
import { Form as SemanticForm } from 'semantic-ui-react'
import {
  composeValidators,
  minLength,
  isRequired,
  maxLength,
  length,
  mustBeNumber,
} from '../../utils/validators'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'

class ContactFormComptabilite extends Component {
  constructor(props) {
    super(props)
    this.state = {isProf : false}
  }

  componentDidMount() {
    this.setState({ ...this.state, isGerant: this.props?.isGerant }) 
    this.setState({ ...this.state, isProf: this.props?.isTVA })
  }
  
  getMentionsInState = () => {
    ipcRenderer.invoke(Channels.MENTION_FIND_ALL, {}).then(({ data }) => {
      this.setState({
        mentions: data,
      })
    })
  }

  render() {
    return (
      <Fragment>
        <SemanticForm.Group widths="equal">
          <Field name="societe.codeJournalVente">
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                className={this.props.isGerant ? '' : 'hidden'}
                label="Code du journal de vente"
                type="text"
                placeholder="VTE"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
          <Field name="societe.compteTierCollectif">
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                className={this.props.isGerant? '' : 'hidden'}
                label="Compte tier collectif"
                type="text"
                placeholder="4110000"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
        </SemanticForm.Group>
        <SemanticForm.Group widths="equal">
          <Field name="societe.compteComptableTVA_2_1">
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                className={this.props.isGerant? '' : 'hidden'}
                label="Compte comptable TVA 2.1%"
                type="text"
                placeholder="457102"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
          <Field name="societe.compteComptableTVA_5_5">
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                className={this.props.isGerant? '' : 'hidden'}
                label="Compte comptable TVA 5.5%"
                type="text"
                placeholder="4457105"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
          <Field name="societe.compteComptableTVA_10">
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                className={this.props.isGerant ? '' : 'hidden'}
                label="Compte comptable TVA 10%"
                type="text"
                placeholder="4457110"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
          <Field name="societe.compteComptableTVA_20">
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                className={this.props.isGerant ? '' : 'hidden'}
                label="Compte comptable TVA 20%"
                type="text"
                placeholder="4457120"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
        </SemanticForm.Group>
      </Fragment>
    )
  }
}

export default ContactFormComptabilite
