import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Button, Form as SemanticForm, Label } from 'semantic-ui-react'
import { Field, Form as FinalForm } from 'react-final-form'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { useForm } from 'react-final-form'

class TraiteSearchForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contacts: [],
    }
  }
  componentDidMount() {
    this.getContactsInState()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      this.getContactsInState()
    }
  }

  getContactsInState = () => {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_ALL, {
        params: {
          isClient: this.props.type === 'VENTE',
          isFournisseur: this.props.type === 'ACHAT',
          isGerant: false,
        },
      })
      .then(({ data }) => {
        this.setState({
          contacts: data,
        })
      })
  }
  handleSubmit = (formValues) => {
    this.props.onSubmit(formValues)
  }
  render() {
    return (
      <FinalForm
        onSubmit={this.handleSubmit}
        initialValues={this.props.initialValues}
        render={({ handleSubmit, form }) => {
          return (
            <SemanticForm onSubmit={handleSubmit}>
              <div className="ui content">
                <SemanticForm.Group>
                  <Field name="numero">
                    {({ input, meta }) => (
                      <SemanticForm.Input
                        {...input}
                        label="Numéro d'avoir"
                        type="text"
                        placeholder="ex: AV-012022-1"
                        autoComplete="off" 
                        error={meta.error && meta.touched ? meta.error : null}
                      />
                    )}
                  </Field>
                  <Field name="numeroFA">
                    {({ input, meta }) => (
                      <SemanticForm.Input
                        {...input}
                        label="Numéro de facture"
                        type="text"
                        placeholder="ex: FF-122021-1"
                        autoComplete="off" 
                        error={meta.error && meta.touched ? meta.error : null}
                      />
                    )}
                  </Field>
                  <Field name="recepteurId">
                    {({ input, meta }) => {
                      const form = useForm()
                      return (
                        <SemanticForm.Field className="min-width-field">
                          <label>
                            {this.props.type === 'ACHAT'
                              ? 'Fournisseur'
                              : 'Client'}
                          </label>
                          <SemanticForm.Dropdown
                            placeholder="Tous les clients"
                            {...input}
                            search
                            fluid
                            selection
                            className="min-width-field"
                            onBlur={(e) => e.preventDefault()}
                            options={this.state.contacts?.map((contact) => {
                              return {
                                key: contact.id,
                                value: contact.id,
                                text: contact.code + ' - ' + contact.nom,
                              }
                            })}
                            onChange={(event, data) => {
                              const contactOption = this.state.contacts.find(
                                (item) => {
                                  return item.id === data.value
                                }
                              )
                              if (contactOption) {
                                form.change(`recepteurId`, contactOption.id)
                              }
                              input.onChange(data.value)
                            }}
                            onSearchChange={this.handleSearchChange}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                      )
                    }}
                  </Field>
                  <div className="blocSearchBtns"
                  style={{
                    alignItems: 'end',
                    gap: '10px',
                    marginLeft: '8px',
                  }}
                >
                    <Button type="submit" 
                     style={{
                     paddingTop: '14px'
                     }}
                  >
                      <i className="icon search" />
                      Rechercher
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        // Reset the form
                        form.reset()
                        this.props.onResetSearch()
                      }}
                      style={{
                        paddingTop: '14px'
                      }}
                    >
                      <i className="icon eraser" />
                      Effacer
                    </Button>
                  </div>
                </SemanticForm.Group>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default connect(null, null)(TraiteSearchForm)
